<template>
  <DtPanelTabsComponent
    :tabs="tabs"
  />
</template>

<script>
export default {
  name: 'DtPanelPlotLoadCurve',
  components: {
    // This way to avoid the recursive problem. Component needs a name
    DtPanelTabsComponent: () => import('@/components/DigitalTwin/DtPanelTabsComponent.vue')
  },
  props: {
    dataPlot: {
      type: Array,
      default: null
    },
    dataPlotLCP: {
      type: Array,
      default: null
    },
    samplingPeriod: {
      type: Number,
      default: null
    }
  },
  computed: {
    // Must be computed because the props will change the value after the component is loaded.
    // Could it be in the DTPanel, but I prefer to make it here
    tabs () {
      return [
        {
          icon: 'I',
          label: this.$t('current'),
          component: 'DtPanelPlotGeneric',
          show: true,
          props: {
            dataPlot: this.dataPlot,
            typeValue: 1,
            samplingPeriod: this.samplingPeriod,
            name: this.$t('kpi.ldc_current'),
            yAxis: 'Current (A)',
            pavY: this.dataPlot[0][1],
            pbvY: this.dataPlot[1][1],
            pcvY: this.dataPlot[2][1],
            pnvY: this.dataPlot[3][1],
            curve: true
          }
        },
        {
          icon: 'P',
          label: this.$t('power'),
          component: 'DtPanelPlotGeneric',
          show: true,
          props: {
            dataPlot: this.dataPlotLCP,
            typeValue: 2,
            samplingPeriod: this.samplingPeriod,
            name: this.$t('kpi.ldc_power'),
            yAxis: 'Power (KW)',
            pavY: this.dataPlotLCP[0][1],
            pbvY: this.dataPlotLCP[1][1],
            pcvY: this.dataPlotLCP[2][1],
            pnvY: this.dataPlotLCP[3][1],
            curve: true
          }
        }
      ]
    }
  }
}
</script>
