<template>
  <v-toolbar class="toolbar">
    <v-container class="p-0 m-0">
      <v-row
        class="toolbar-row m-0"
        fluid
      >
        <v-col class="d-flex col-sm-4 p-0">
          <v-card-text class="text-left title-toolbar">
            {{ typeElement }} {{ idElementToShow }}
          </v-card-text>
        </v-col>

        <div
          v-if="!panelOnlyTitle"
          class="col-sm-8"
        >
          <div class="row">
            <v-col
              v-if="typeElement === 'station' || typeElement === 'Station'"
              class="d-flex col-sm-5 p-0"
            >
              <v-card-text class="text-left title-toolbar">
                <v-autocomplete
                  v-model="transformerSelected"
                  dense
                  :items="transformersList"
                  auto-select-first
                  item-text="Name"
                  item-value="Name"
                  label="Transformer"
                  return-object
                />
              </v-card-text>
            </v-col>

            <v-col class="text-end p-0 mt-1 col-sm-1">
              <v-icon>
                {{ calendarIcon }}
              </v-icon>
            </v-col>
            <v-col class="text-end col-sm-6 p-0">
              <v-menu
                id="menu-toolbar"
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="23"
                :nudge-top="27"
                transition="scale-transition"
                offset-y
                min-width="260px"
                :left="true"
              >
                <template #activator="{ on: menu, attrs }">
                  <v-tooltip
                    :nudge-top="20"
                    bottom
                  >
                    <template #activator="{ on: tooltip }">
                      <v-text-field
                        v-model="datesString"
                        class="choose-date"
                        label=""
                        readonly
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                      />
                    </template>
                    <span>Click to change</span>
                  </v-tooltip>
                </template>
                <v-card>
                  <v-divider />
                  <v-list :dense="true">
                    <v-list-item>
                      <v-menu
                        v-model="startDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="20"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        :left="true"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                            v-model="initialDate"
                            label="Initial date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="initialDate"
                          color="#f4c020"
                          :min="dtInitialDate"
                          :max="initialDateMax"
                          @input="startDateMenu = false"
                        />
                      </v-menu>
                    </v-list-item>
                    <v-list-item>
                      <v-menu
                        v-model="endDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="20"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        :left="true"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                            v-model="endDate"
                            label="End date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="endDate"
                          color="#f4c020"
                          :min="endDateMin"
                          :max="dtEndDate"
                          @input="endDatemenu = false"
                        />
                      </v-menu>
                    </v-list-item>
                  </v-list>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      text
                      @click="dateMenu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      id="ok-menu-toolbar"
                      class="color-primary"
                      text
                      @click="dateChanged()"
                    >
                      OK
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>
          </div>
        </div>
      </v-row>
    </v-container>
  </v-toolbar>
</template>

<script>
import { mdiCalendar } from '@mdi/js'

export default {
  name: 'DigitalTwinPanelToolbar',
  props: {
    idElementToShow: {
      type: String,
      default: null
    },
    typeElement: {
      type: String,
      default: null
    },
    panelOnlyTitle: {
      type: Boolean,
      default: false
    },
    transformersList: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    calendarIcon: mdiCalendar,
    navigation: {
      shown: false,
      width: 200,
      borderSize: 3
    },
    // Selected dates in the toolbar
    initialDate: null,
    endDate: null,
    // menus to show
    dateMenu: false,
    startDateMenu: false,
    endDateMenu: false,
    // Fixed dates. Don't change in this Digital twin
    dtInitialDate: null,
    dtEndDate: null,
    transformerSelected: null
  }),
  computed: {
    endDateMin () {
      return `${this.initialDate}`
    },
    initialDateMax () {
      return `${this.endDate}`
    },
    datesString () {
      return `${this.initialDate} / ${this.endDate}`
    }
  },
  watch: {
    transformersList (value) {
      this.transformerSelected = this.transformersList[0]
    },
    transformerSelected () {
      this.$emit('updateStation', this.transformerSelected.ID)
    }
  },
  mounted () {
    // CASE CONFIGURATION
    this.initialDate = this.$store.state.DTProject.sim.InitDate
    this.endDate = this.$store.state.DTProject.sim.EndDate
    this.dtInitialDate = this.$store.state.DTProject.sim.InitDate
    this.dtEndDate = this.$store.state.DTProject.sim.EndDate
  },
  methods: {
    dateChanged () {
      this.dateMenu = false // Hide the Menu to change dates

      let initialFrame = 0
      let lastFrame = 0

      let initDaten = new Date(this.initialDate).getTime()
      let endDaten = new Date(this.endDate).getTime()

      const fechaIni = new Date(this.initialDate)
      const gapIni = fechaIni.getTimezoneOffset()
      initDaten += gapIni * 60 * 1000

      const fechaFin = new Date(this.endDate)
      const gapFin = fechaFin.getTimezoneOffset()
      endDaten += gapFin * 60 * 1000

      const diffInitTStarT = initDaten - this.$store.state.DTProject.sim.StartTime
      initialFrame = diffInitTStarT / (1000 * 60 * 60) // in hours
      initialFrame *= (3600 / this.$STEP_TIME) // due to sampleTime
      lastFrame = (endDaten - initDaten) / (1000 * 60 * 60) // in hours
      lastFrame *= (3600 / this.$STEP_TIME) // due to sampleTime
      lastFrame = lastFrame + 24 * (3600 / this.$STEP_TIME) + initialFrame // due to sampleTime

      this.$store.dispatch('setElement', { path: 'DTProject.sim.InitDate', value: this.initialDate })
      this.$store.dispatch('setElement', { path: 'DTProject.sim.EndDate', value: this.endDate })

      this.$emit('updateToolbar', initialFrame, lastFrame)
    }
  }
}

</script>

<style>
.v-toolbar.toolbar {
  height: 60px;
  max-height: 60px;
  background-color: #1e1e1e;
  padding: 4px 10px;
}

.v-toolbar.toolbar *,
.color-primary {
  color: #f4c020 !important;
}

.toolbar-row {
  height: 36px;
}

.title-toolbar {
  padding: 3px 3px 3px 3px;
  font-size: 1.2rem;
  margin-left: -10px;
  margin-top: 1px
}

.toolbar {
  font-family: 'Faktum Regular', sans-serif;
}
</style>
