// GLOBAL VARIABLES
import Vue from 'vue'
import datadog from '@/assets/datadog/datadog'

//API Data
Vue.prototype.$API_HOST = process.env.VUE_APP_API_HOST
Vue.prototype.$API_PORT = process.env.VUE_APP_API_PORT
Vue.prototype.$HEADER_HTTP = process.env.VUE_APP_HEADER_HTTP

//Map Data
if (process.env.VUE_APP_IS_MAP_SERVER_CONTAINER === 'true') {
  Vue.prototype.$MAP_SERVER_URL = process.env.VUE_APP_MAP_SERVER_CONTAINER_URL
} else {
  Vue.prototype.$MAP_SERVER_URL = process.env.VUE_APP_MAP_SERVER_URL
}

Vue.prototype.$TIME_SPLIT = process.env.VUE_APP_TIME_SPLIT
Vue.prototype.$STEP_TIME = process.env.VUE_APP_STEP_TIME
Vue.prototype.$TIME_INTERVAL = process.env.VUE_APP_TIME_INTERVAL

Vue.prototype.$WGS84 = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs'
Vue.prototype.$DEBUG = (process.env.VUE_APP_DEBUG === 'true')
