var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('L-Marker-Cluster',{attrs:{"options":_vm.clusterOptions}},_vm._l((_vm.stations),function(station){return _c('div',{key:station.id},[_c('l-marker',{attrs:{"lat-lng":_vm.getLatLongProj(station.coordinates[0], station.coordinates[1])},on:{"click":function($event){return _vm.clickIcon(station.id, station.name,station.max_power)},"contextmenu":_vm.openContextMenu}},[_c('StationIcon'),(station.flex_configuration === 'ready')?_c('div',[_c('StationIcon',{attrs:{"name":station.name}})],1):_c('div',[_c('StationIconForecast',{attrs:{"uuid":station.id,"name":station.name,"forecast-status":_vm.calculateStatus(station.max_power,station.forecasted_status.power,
            station.forecasted_status.active_reservation),"status":_vm.calculateStatus(station.max_power,station.current_status.power,
            station.current_status.active_reservation),"power-values":[station.max_power,station.min_power,station.current_status.power],"status-name":_vm.getNameStatus(_vm.calculateStatus(station.max_power,station.current_status.power,
            station.current_status.active_reservation)),"status-color":_vm.getColorStatus(_vm.calculateStatus(station.max_power,station.current_status.power,
            station.current_status.active_reservation)),"status-name-forecast":_vm.getNameStatus(_vm.calculateStatus(station.max_power,station.forecasted_status.power,
            station.forecasted_status.active_reservation)),"status-color-forecast":_vm.getColorStatus(_vm.calculateStatus(station.max_power,station.forecasted_status.power,
            station.forecasted_status.active_reservation))}})],1)],1)],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }