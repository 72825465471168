<template>
  <v-dialog
    v-model="showForm"
    style="z-index:501"
    max-width="600"
    overlay-opacity="0.5"
  >
    <v-card elevation="10">
      <v-form
        ref="ESSForm"
        v-model="valid"
        lazy-validation
      >
        <v-card-title class="text-h5">
          {{ $t('new_connections.ess_connection_parameters') }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <!-- Node Parameter -->
                <v-text-field
                  v-model="values.Node"
                  :rules="VALIDATIONS.node"
                  :label="$t('new_connections.ess_node')"
                  disabled
                  dense
                />
                <!-- Mode Parameter -->
                <v-select
                  v-model="values.mode"
                  :items="modeESS"
                  :label="$t('new_connections.ess_mode')"
                  dense
                >
                  <template
                    #append-outer
                  >
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-icon v-on="on">
                          {{ mdiInformation }}
                        </v-icon>
                      </template>
                      <div class="text-left">
                        <ul class="pl-3">
                          <li>{{ $t('new_connections.peak_shave_description') }}</li>
                        </ul>
                      </div>
                    </v-tooltip>
                  </template>
                </v-select>>
                <!-- Ph Parameter -->
                <v-select
                  v-model="values.ph"
                  :items="phESS"
                  :label="$t('new_connections.ess_phase_conn')"
                  dense
                />
              </v-col>
              <v-col cols="6">
                <!-- Line ID Parameter -->
                <v-select
                  v-model="sid"
                  :items="lineIds"
                  :label="$t('new_connections.monitored_element')"
                  dense
                  @change="onChange"
                />
                <!-- Direction -->
                <v-select
                  v-model="flowDirection"
                  :items="sigModeESS"
                  :label="$t('new_connections.ess_sigmode')"
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-expansion-panels multiple>
                <v-expansion-panel>
                  <v-expansion-panel-header>{{ $t('active_power') }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="values.PmaxIn"
                          :rules="VALIDATIONS.pmaxin"
                          :label="$t('new_connections.ess_pmaxin')"
                          type="number"
                          suffix="kW"
                          dense
                          @change="setToNumber('PmaxIn')"
                        />

                        <v-text-field
                          v-model="values.PmaxOut"
                          :rules="VALIDATIONS.pmaxout"
                          :label="$t('new_connections.ess_pmaxout')"
                          type="number"
                          suffix="kW"
                          dense
                          @change="setToNumber('PmaxOut')"
                        />
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>{{ $t('reactive_power') }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="values.QmaxIn"
                          :rules="VALIDATIONS.qmaxin"
                          :label="$t('new_connections.ess_qmaxin')"
                          type="number"
                          suffix="kVar"
                          dense
                          @change="setToNumber('QmaxIn')"
                        />

                        <v-text-field
                          v-model="values.QmaxOut"
                          :rules="VALIDATIONS.qmaxout"
                          :label="$t('new_connections.ess_qmaxout')"
                          type="number"
                          suffix="kVar"
                          dense
                          @change="setToNumber('QmaxOut')"
                        />
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>{{ $t('new_connections.ess_emax') }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="values.Emax"
                          :rules="VALIDATIONS.emax"
                          :label="$t('new_connections.ess_emax')"
                          type="number"
                          suffix="kWh"
                          dense
                          @change="setToNumber('Emax')"
                        />
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>{{ $t('new_connections.state_charge') }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="values.SOC1"
                          :rules="VALIDATIONS.soc1"
                          :label="$t('new_connections.min_state_charge')"
                          type="number"
                          suffix="%"
                          dense
                          @change="setToNumber('SOC1')"
                        />

                        <v-text-field
                          v-model="values.SOC2"
                          :rules="VALIDATIONS.soc2"
                          :label="$t('new_connections.max_state_charge')"
                          type="number"
                          suffix="%"
                          dense
                          @change="setToNumber('SOC2')"
                        />

                        <v-text-field
                          v-model="values.SOC"
                          :rules="VALIDATIONS.soc"
                          :label="$t('new_connections.current_state_charge')"
                          type="number"
                          suffix="%"
                          dense
                          @change="setToNumber('SOC')"
                        />
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>{{ $t('new_connections.trigger_limit') }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="values.TrigerLow"
                          :rules="[validateLowTrigger]"
                          :label="$t('new_connections.lower_power_limit')"
                          type="number"
                          suffix="kW"
                          dense
                          @change="setToNumber('TrigerLow')"
                        />

                        <v-text-field
                          v-model="values.TrigerHigh"
                          :rules="[validateHighTrigger]"
                          :label="$t('new_connections.upper_power_limit')"
                          type="number"
                          suffix="kW"
                          dense
                          @change="setToNumber('TrigerHigh')"
                        />

                        <v-text-field
                          v-model="values.TrigerBand"
                          :label="$t('new_connections.band_power_limit')"
                          type="number"
                          suffix="kW"
                          dense
                          @change="setToNumber('TrigerBand')"
                        />
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#f4c020"
            text
            @click="closeForm"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="payload.edit"
            :disabled="!valid"
            color="#f4c020"
            text
            @click="editDevice"
          >
            Edit
          </v-btn>
          <v-btn
            v-if="payload.edit"
            color="#f4c020"
            text
            :disabled="!valid"
            @click="deleteDevice"
          >
            Delete
          </v-btn>
          <v-btn
            v-else
            :disabled="!valid"
            data-cy="dt-add-ev"
            color="#f4c020"
            text
            @click="essDevice"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import digitalTwinForm from '@/mixins/digitalTwinForm'
import { mapState } from 'vuex'
import { mdiInformation } from '@mdi/js'

export default {
  name: 'NewConnectionsESSForm',

  mixins: [digitalTwinForm],

  data () {
    return {
      mdiInformation,
      DEVICE: 'ESS',
      values: {
        mode: 4,
        Node: 0,
        ph: 4,
        SigID: 78213,
        SigMode: 3,
        PmaxIn: 3,
        PmaxOut: 3,
        QmaxIn: 1,
        QmaxOut: 1,
        Emax: 5,
        SOC1: 20,
        SOC2: 90,
        SOC: 50,
        P_pq_mode: 3,
        Q_pq_mode: 1,
        dv1: 0.01,
        dv2: 0.02,
        vreg: 1,
        TrigerLow: 35,
        TrigerHigh: 70,
        TrigerBand: 0.1
      },
      lineParams: [],
      lineIds: [],
      flowDirection: 'out',
      cgpid: 0,
      stationid: 0,
      sid: 0,
      contextMenutype: String
    }
  },

  computed: {
    ...mapState({
      digitalTwinResults: (state) => state.DTResults,
      DTChanges: (state) => state.DTChanges
      // ess: (state) => state.DTChanges.ESS
    })
  },

  created () {
    this.configOptions()
  },

  methods: {

    configOptions () {
      this.contextMenutype = this.$store.state.contextMenuElement.type // Gets the context menu type such as contextMenutype

      if (this.contextMenutype === 'contextMenuStation') { // for menu station
        this.stationid = this.$store.state.contextMenuElement.info.ID // Gets the station ID
        const stationBusId = this.digitalTwinResults.getStationBusId(this.stationid)
        this.values.Node = parseInt(stationBusId[0][0]) // converts the station ID
        const stationLines = this.digitalTwinResults.getStationLines(this.stationid) // Gets the Line ID connected to the CGP
        const stationLneId = [] // Array storage for the line id's

        if (stationLines) {
          for (const index of stationLines) {
            if (stationLines[index][0] === stationLines[index][3]) { // If BUS2 is equal to either Bus1 or Bus2
              stationLneId.push(stationLines[index][2])
            } else {
              stationLneId.push(stationLines[index][3])
            }
          }
          this.lineIds = stationLneId
        }

        this.sid = stationLneId[0]
      } else { // for connection point menu
        this.cgpid = this.$store.state.contextMenuElement.info.ID // Gets the CGP/connetion point ID

        // The cgpID could be from an existing id in database or not if is a added CGP_New
        const index = this.DTChanges.CGP_New.CGP.indexOf(this.cgpid)
        let busId = null

        if (index < 0) {
          busId = this.digitalTwinResults.getBusId(this.cgpid)[0][0] // Gets the Bus ID
          this.values.Node = parseInt(busId) // Assign bus id on node as the default value
        } else {
          busId = this.DTChanges.CGP_New.Node[index] // Gets the Bus ID
          this.values.Node = busId // Assign bus id on node as the default value
        }

        this.lineParams = this.digitalTwinResults.getLineId(busId) // Gets the Line ID connected to the CGP
        this.lineIds = this.lineParams.map(function(line) {
          return line[0]
        })
        this.sid = this.lineIds[0]
        /*
        const lineFlow = this.digitalTwinResults.getLineFlow(busId)
        this.values.SigMode = 3
        if (lineFlow === 1) {
          this.values.SigMode = 4
        }
        */
      }
    },

    onChange () {
      this.values.SigID = this.sid
    },

    validateLowTrigger (value) {
      const highValue = this.values.TrigerHigh
      return (!highValue || value <= highValue) || `${this.$t('new_connections.input_less_or_equal_to_high')}`
    },
    validateHighTrigger (value) {
      const lowValue = this.values.TrigerLow
      return (!lowValue || value >= lowValue) || `${this.$t('new_connections.input_greater_or_equal_to_low')}`
    },

    essDevice () {
      this.onChange()

      // This value says if the source of the line is on the bus of the battery
      const isSrcOnBus = this.lineParams.find(line => line[0] === this.sid)?.[1]
      // SigMode depends on some things previously selected, so we have to assign a value here
      if ((this.flowDirection === 'out' && isSrcOnBus) || (this.flowDirection === 'in' && !isSrcOnBus)) {
        this.values.SigMode = 3
      } else if ((this.flowDirection === 'in' && isSrcOnBus) || (this.flowDirection === 'out' && !isSrcOnBus)) {
        this.values.SigMode = 4
      }

      const dtChangeValue = this.$store.state.DTChanges.ESS
      const objKeys = Object.keys(dtChangeValue)
      const objValues = Object.values(dtChangeValue)
      const entries = Object.entries(this.values)
      const obj = {}

      if (Array.isArray(dtChangeValue.mode) && !dtChangeValue.mode.length) {
        entries.forEach(([key, value], index) => {
          const shifted = entries[index].shift()
          obj[shifted] = (shifted, entries[index])
        })
      } else {
        entries.forEach(([key, value], index) => {
          if (key === objKeys[index]) {
            const shifted = entries[index].shift()
            const returnValue = this.loopFunction(entries[index], objValues[index])
            obj[shifted] = (shifted, returnValue)
          }
        })
      }

      this.$store.dispatch('setElement', { path: 'DTChanges.ESS', value: obj })
      this.addDevice()
      this.closeForm()
    },

    loopFunction (entries, changesValue) {
      for (let index = 0; index < changesValue.length; index++) {
        entries.push(changesValue[index])
      }
      return entries
    }

  }
}
</script>

<style scoped>

.row.charging-time {
  align-items: center;
}

.v-range-input {
  width: 36px;
}

.v-application--is-ltr .v-input__slider .v-input__slot .v-label {
  background: rebeccapurple;
}

.text-left {
  text-align: left;
}
.msg {
  margin-top: 3rem;
  text-align: center;
}
</style>
