<template>
  <div>
    <VCol
      cols="12"
      sm="12"
    >
      <VAutocomplete
        v-model="elementType"
        :items="types"
        name="type"
        :label="$t('grid_set.type_element')"
        dark
        required
        @change="onChange"
      />
    </VCol>
    <VCol cols="12">
      <VTextField
        v-model.trim="elementId"
        name="element"
        :label="$t('grid_set.element_id')"
        type="text"
        dark
        required
        @input="onChange"
      />
    </VCol>
    <VCol cols="12">
      <VTextField
        v-model.trim="elementName"
        name="element"
        :label="$t('grid_set.element_name')"
        type="text"
        dark
        required
        @input="onChange"
      />
    </VCol>
    <VCol
      cols="12"
      sm="12"
      class="radius-container"
    >
      {{ $t('grid_set.radius') }}* {{ radius }} (m)
      <VSlider
        v-model="radius"
        name="distance"
        step="10"
        max="1000"
        min="0"
        thumb-label
        ticks
        dark
        @change="onChange"
        @input="onChange"
      />
    </VCol>
  </div>
</template>

<script>
export default {
  name: 'ASElementForm',

  data () {
    return {
      elementType: null,
      elementId: null,
      elementName: null,
      radius: 0
    }
  },

  computed: {
    types () {
      return [
        this.$t('grid.meter'),
        this.$t('grid.station'),
        this.$t('grid.connection_point')
      ]
    }
  },

  mounted () {
    this.setInitialValues()
  },

  methods: {
    onChange () {
      this.$emit('updateModel', {
        value: {
          type: this.elementType,
          name: this.elementName,
          id: this.elementId,
          radius: this.radius
        }
      })
    },

    setInitialValues () {
      const rawGridElement = this.$sessionStorage?.gridElement || '{}'
      const isGridElementStoraged = rawGridElement !== null

      if (isGridElementStoraged) {
        const { type, id, radius, name } = JSON.parse(rawGridElement) || {}

        this.elementType = type
        this.elementId = id
        this.radius = radius
        this.elementName = name
      }
    }
  }
}
</script>

<style scoped>
.v-autocomplete, .radius-container {
  color: white !important;
}
</style>
