<template>
<div>
  <CMLoader
    v-if="uploading"
    :loading="uploading"
    :message="$t('importer.uploading_file') + ': ' + loadPercentage +'%'"
  />
  <v-form
    v-if="headers"
    ref="newProjectForm"
    v-model="valid"
    lazy-validation
  >
      <v-row
        v-if="!sent"
        align="center"
        align-content="center"
        justify="center"
        style="padding: 0px 20px"
      >
        <v-col
          cols="12"
          self-align="center"
          style="margin-top:30px"
          class="form-text"
        >
          <v-autocomplete
            v-model="refValue"
            dark
            dense
            clearable="true"
            :items="refOptions"
            :label="$t('importer.reference_column')"
          />
        </v-col>
        <v-col
          cols="12"
          self-align="center"
          class="form-text"
        >
          <v-autocomplete
            v-model="timeValue"
            dark
            dense
            clearable="true"
            :items="timeOptions"
            :label="$t('importer.time_column')"
          />
        </v-col>
        <div v-if="refValue !== null && timeValue !== null">
          <v-row
            v-for="(measurement,index) in measurementsOptionsAll"
            :key="index"
          >
            <v-col
              cols="6"
              self-align="center"
              class="form-text"
            >
              <v-autocomplete
                v-model="signalsValues[index]"
                dark
                dense
                clearable="true"
                :items="signalsOptions[index]"
                :label="$t('importer.signal') "
                item-text="text"
                item-value="value"
              />
            </v-col>
            <v-col
              cols="6"
              self-align="center"
              class="form-text"
            >
              <v-autocomplete
                v-model="measurementsValues[index]"
                dark
                dense
                clearable="true"
                :items="measurementsOptions[index]"
                :label="$t('importer.measurement_column')"
              />
            </v-col>
          </v-row>
        </div>
        <v-col
          cols="12"
          self-align="center"
        >
          <v-btn
            v-if="!uploading"
            color="#f4c020"
            text
            style="margin:20px"
            :disabled="!formReady"
            @click="uploadMeasurementsFile"
          >
            {{ $t('importer.load_file') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row
      v-else
      align="center"
      align-content="center"
      justify="center"
    >
      <v-col
        cols="12"
        self-align="center"
        style="margin:30px 10px 0px 10px"
        class="form-text"
      >
        <v-alert
          :type="response.type"
          :color="response.color"
          dismissible="true"
        >
          <p>{{ response.message }}</p>
        </v-alert>
      </v-col>
    </v-row> 
  </v-form>
</div>
</template>

<script>

import i18n from '@/plugins/i18n'
import { getMeasurements } from '@/services/measurements'
import { postMeasurementFile } from '@/services/dataImporter'

export default {
  name: 'MeasurementsDataUpload',

  components: {
    CMLoader: () => import('@/components/Common/CMLoader.vue'),
    CMError: () => import('@/components/Common/CMError.vue')
  },

  props: {
    headers: {
      type: Array,
      default: null
    },
    content: {
      type: Object,
      default: null
    },
    parsed: {
      type: Boolean,
      default: false
    },
    fileData: {
      type: File,
      default: null
    },
    fileHeaders: {
      type: Array,
      default: null,
    },
    fileTitle: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      valid: true,
      sent: false,
      refValue: null,
      timeValue: null,
      measurementsValues: [this.headers.length - 2],
      signals: null,
      signalsValues: [this.headers.length - 2],
      signalsReady: false,
      formReady: false,
      hasError: false,
      uploading: false,
      batchNumRows: 100000, //number of lines
      currentBatch: 1,
      currentRow: 0,
      chunkFileName: this.fileNameWithoutExtension+'_1.csv',
      chunkFileData: '',
      response: {
        type: 'info',
        color: '#ccc',
        message: '',
        code: 0
      },
      batchErrors: [],
      batchsLoaded: 0,
      loadPercentage: 0
    }
  },

  computed: {
    refOptions () {
      return this.headers.filter(header => header !== this.timeValue && !this.measurementsValues.includes(header))
    },

    timeOptions () {
      return this.headers.filter(header => header !== this.refValue && !this.measurementsValues.includes(header))
    },

    signalsOptions () {
      const signals = new Array(this.headers.length - 2)
      for (let i = 0; i < this.headers.length - 2; i += 1) {
        signals[i] = this.signals
      }
      this.signalsValues.forEach((value, valueIndex) => {
        if (value !== null) {
          for (let i = 0; i < this.headers.length - 2; i += 1) {
            if (valueIndex !== i) {
              signals[i] = signals[i].filter(header => header.value !== value)
            }
          }
        }
      })
      return signals
    },

    measurementsOptionsAll () {
      return this.headers.filter(header => header !== this.refValue && header !== this.timeValue)
    },
    
    measurementsOptions () {
      const measurements = new Array(this.headers.length - 2)
      for (let i = 0; i < this.headers.length - 2; i += 1) {
        measurements[i] = this.measurementsOptionsAll
      }
      this.measurementsValues.forEach((value, valueIndex) => {
        if (value !== null) {
          for (let i = 0; i < this.headers.length - 2; i += 1) {
            if (valueIndex !== i) {
              measurements[i] = measurements[i].filter(header => header !== value)
            }
          }
        }
      })
      return measurements
    },

    configParams () {
      return {
        timestamp: this.timeValue,
        reference: this.refValue,
        measurement: this.measurementsValues.toString(),
        codes: this.signalsValues.toString()
      }
    },

    fileNameWithoutExtension() {
      return this.fileTitle.slice(0, -4)
    }
  },

  watch: {
    refValue () {
      this.checkFormReady()
    },
    timeValue () {
      this.checkFormReady()
    },
    measurementsValues () {
      this.checkFormReady()
    }
  },

  async mounted () {
    this.signals = await getMeasurements()
    this.signalsReady = true
    this.signalsValues[0] = null
  },

  methods: {
    checkFormReady () {
      const measurementIsSelected = this.measurementsValues.filter(measurement => measurement !== null)
      const signalIsSelected = this.signalsValues.filter(signal => signal !== null)
      if (this.refValue !== null && this.timeValue !== null && measurementIsSelected.length > 0 && signalIsSelected.length > 0) {
        this.formReady = true
      } else {
        this.formReady = false
      }
    },

    handleResponses(message){
      message.forEach((msg) => {
        if (msg.response !== 'success') {
          this.batchErrors.push(msg.numBatch)
        }
      })
      if(this.batchErrors.length === 0) {
        this.response.code = 200
        this.response.type = 'success'
        this.response.color = '#089611'
        this.response.message = `${i18n.t('file')} ${this.fileTitle} ${i18n.t('importer.has_been_successfully_uploaded')}`
      }else {
        this.response.code = 500
        this.response.type = 'error'
        this.response.color = '#C1422E'
        this.response.message = `${i18n.t('importer.failed_uploading_file')} ${this.fileTitle} \n`
        this.batchErrors.forEach((batch) => {
          const initLine = batch === 1 ? 1 : (batch * this.batchNumRows) + 1
          const endLine = (initLine + this.batchNumRows) - 1
          this.response.message += `${i18n.t('importer.error_between_lines')} ${initLine} ${i18n.t('and')} ${endLine} \n`
        })
      }
    },

    fileUploaded(message){
      this.handleResponses(message)
      this.sent = true
      this.uploading = false
    },

    async postChunk (fileName, fileData, numBatch){
      const chunkFile = {
        data: new File([fileData], fileName, {type:"text/csv"}),
        title: fileName
      }
      const response = await postMeasurementFile (chunkFile, this.configParams)
      this.batchsLoaded ++
      this.loadPercentage = (this.batchsLoaded / this.currentBatch) * 100
      return { response, numBatch}
    },

    splitContent (content, numRows){
      return new Promise((resolve) => {
        const promises = []
        const headers = Object.values(content.data[0]).join(',') + '\n'
        content.data.forEach((row, index) => {
          if(this.currentRow === 0){
            this.chunkFileData += headers
            this.currentRow++
          }
          if(index > 1){
            this.chunkFileData += Object.values(row).join(',')+'\n'
            this.currentRow++ 
            if(this.currentRow >= numRows){
              //initialize to start a new batch
              promises.push(this.postChunk(this.chunkFileName, this.chunkFileData, this.currentBatch))
              this.currentBatch ++
              this.currentRow = 0
              this.chunkFileName = `${this.fileNameWithoutExtension}_${this.currentBatch}.csv`
              this.chunkFileData = ''
            }
          }
        })
        Promise.all(promises).then(results => {     
          resolve(results)
        })
      })
    },

    async uploadMeasurementsFile () {
      this.uploading = true
      const response = await this.splitContent (this.content, this.batchNumRows) 
      this.fileUploaded(response)
    }
  }
}
</script>

<style scoped>
.form-text{
  color:#cccccc; font-family:'Faktum Light', sans-serif;
}
p{
  white-space:pre-wrap
}
</style>
