/* eslint-disable no-unused-expressions */
import Vue from 'vue'
import Vuex from 'vuex'
import {
  set, get, unset
} from 'lodash'
import {
  APPEND_CASE,
  TOGGLE_ELEMENT,
  SET_ELEMENT,
  DTCHANGES_EMPTY,
  DELETE_ELEMENT,
  INITIAL_STATE,
  RESET_STATE
} from './constants'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: INITIAL_STATE(),

  mutations: {
    TOGGLE_ELEMENT (state, payload) {
      payload.value
        ? (state[payload.path].isVisible = payload.value)
        : (state[payload].isVisible = !state[payload].isVisible)
    },

    SET_ELEMENT (state, { path, value, root }) {
      set(state, path, value)
      root && set(state, root, { ...state[root] })
    },

    RESET_STATE (state) {
      Object.assign(state, INITIAL_STATE())
    },

    DELETE_ELEMENT (state, { path, force }) {
      force
        ? Vue.delete(state, path)
        : unset(state, path)
    },

    APPEND_CASE (state, { path, value }) {
      const prevElements = get(state, path)

      set(state, path, {
        ...prevElements,
        [value.name]: { mod: value.mod }
      })
    }
  },

  actions: {
    toggleElement ({ commit }, payload) {
      commit(TOGGLE_ELEMENT, payload)
    },

    setNewPosition ({ commit }, { x, y, toggle }) {
      commit(SET_ELEMENT, {
        path: 'contextMenu.position',
        value: { x, y }
      })

      commit(TOGGLE_ELEMENT, toggle)
    },

    setClick ({ commit }, { lat, lng, idElement }) {
      commit(SET_ELEMENT, {
        path: 'click',
        value: { lat, lng, idElement }
      })
    },

    setElement ({ commit }, payload) {
      commit(SET_ELEMENT, payload)
    },

    resetState ({ commit }) {
      commit(RESET_STATE)
    },

    deleteElement ({ commit }, payload) {
      commit(DELETE_ELEMENT, payload)
    },

    appendCase ({ commit }, payload) {
      commit(APPEND_CASE, payload)
    },

    setDTChange ({ commit }, {
      path, values, append, remove, customIndex
    }) {
      const device = get(this, `state.DTChanges.${path}`)
      const results = get(this, 'state.DTResults.Results')
      const markerId = get(this, 'state.currentMarker.id')
      const newCgps = get(this, 'state.DTChanges.CGP_New')
      const entries = Object.entries(values)
      let deviceChangeIndex

      if (!append) {
        // changeIndex will indicate how many connections of any path are in this marker
        const [cpId, changeIndex] = markerId.split('-').map(Number)
        let counter = -1
        let busId
        // Know if the element is inside a CGP_New
        const isNewCGPposition = newCgps.CGP.indexOf(cpId) // If it's old return -1, if not the index

        if (isNewCGPposition >= 0) {
          busId = newCgps.Node[isNewCGPposition]
        } else {
          // We've got the CGP and we want the Node/Bus
          const cgpIndex = results.GetCgpIndex(cpId)
          const busIndex = results.CGP_Bus[cgpIndex]
          busId = results.Bus_ID[busIndex]
        }

        // Function to find the index of the CGP/Node that we should change/delete
        const getDeviceChangeIndex = (deviceArray, comparingId) => {
          deviceArray.forEach((id, index) => {
            if (id === comparingId) {
              // counter is used to know how many connections of this path (Ej. EV) are in this CGP
              counter += 1
              // customIndex is the index from this type of connection in this CGP
              // The origin of customIndex is indexes in DTEditChangesForm.vue. There could be [0,0,1,2] (from PV,EV,EV,EV)
              // If you select the last EV, deviceChangeIndex will come as 2
              if (counter === customIndex) {
                deviceChangeIndex = index
              }
            }
          })
        }

        if (device.CGP) {
          // EV, PV, HP, CP, CGP_New
          getDeviceChangeIndex(device.CGP, cpId)
        } else {
          // ESS
          getDeviceChangeIndex(device.Node, busId)
        }
      }

      entries.forEach(([key, value]) => {
        if (remove) {
          // deleteDevice
          const removeItem = (_, index) => index !== deviceChangeIndex
          device[key] = device[key].filter(removeItem)
        } else {
          // addDevice or updateDevice
          append
            ? device[key] = [...device[key], value]
            : device[key][deviceChangeIndex] = value
        }
      })

      commit(SET_ELEMENT, {
        path: `DTChanges.${path}`,
        value: device,
        root: 'DTChanges'
      })
    }
  },

  getters: {
    isContextMenu: ({ contextMenu }) => get(contextMenu, 'isVisible', false),

    lines: ({ DTProject }) => {
      const lines = []
      const networks = get(DTProject, 'networksInfo')
      networks.forEach(level => {
        level.networks.forEach(network => {
          lines.push(network.lines)
        })
      })
      return lines.flat(1)
    },

    connectionPoints: ({ DTProject }) => {
      const connectionPoints = []
      const networks = get(DTProject, 'networksInfo')
      networks.forEach(level => {
        level.networks.forEach(network => {
          connectionPoints.push(network.connection_points)
        })
      })
      return connectionPoints.flat(1)
    },

    fuses: ({ DTProject }) => {
      const networks = get(DTProject, 'networksInfo')
      const closedFuses = []
      const openFuses = []
      const switchBoxesFuses = []
      networks.forEach(level => {
        level.networks.forEach(network => {
          closedFuses.push(network.closed_fuses)
        })

        openFuses.push(level.openFuses)

        level.switchBoxes.forEach(switchBox => {
          switchBoxesFuses.push(switchBox.fuses)
        })
      })

      return [
        ...closedFuses.flat(1) ?? [],
        ...(openFuses.flat(1) ?? []),
        ...(switchBoxesFuses.flat(1) ?? [])
      ]
    },

    stations: ({ DTProject }) => {
      const stations = []
      const networks = get(DTProject, 'networksInfo')
      networks.forEach(level => {
        stations.push(level.stations)
      })
      return stations.flat(1)
    },

    cases: ({ DTResults }) => {
      const casesAsObj = get(DTResults, 'cases', {})
      const casesAsArr = Object.entries(casesAsObj)
      return casesAsArr.map(
        ([name, data]) => {
          const hasChanges = data.mod && Object.keys(data.mod).length

          return ({
            ...data,
            name,
            mod: hasChanges ? data.mod : DTCHANGES_EMPTY()
          })
        }
      )
    },

    currentMod: ({ DTResults, currentCase }) => {
      if (currentCase) {
        const { cases } = DTResults
        const caseWithMod = cases[currentCase]

        return caseWithMod && caseWithMod.mod
      }

      return undefined
    },

    HCManageResultsSolved: state => {
      return state.hostingCapacity.HCManageResults.filter((el) => el.infoHCM.is_solved)
    }
  }
})

export default store
