import { get } from '@/assets/queries'

const d3 = require('d3')

// Init variables
// variables for travel array
let pfather
let maxDist
// diccionaries for distance and voltage
const colors = []
// array for de branch hides
const branchHide = []

function generateRandomNumber (numero) {
  // Function to put the random color for each line
  return (Math.random() * numero).toFixed(0)
}

function colorRGB () {
  // Function to generate a random RGB color code
  const color = `(${generateRandomNumber(255)},${generateRandomNumber(255)},${generateRandomNumber(255)})`
  return `rgb${color}`
}

function getMaxTime (pfrObj) {
  return pfrObj.BufferSize / 8 / pfrObj.BusCount
}

function getVoltageValue (pfrObj, busId, frameId, instant, variable, relative) {
  // Function to calculate the voltage value.
  // Args:
  //  * busId: id of the bus in the network (name)
  //  * frameId: id of the case in PowerFlowResults object
  //  * instant: instant to represent
  //  * variable: selector for the operation
  //    - 1: Minimum
  //    - 2: Maximum
  //    - 3: Average
  //    - 4: A
  //    - 5: B
  //    - 6: C
  //    - 7: N
  //    - 8: All time minimum (instant not considered)
  //    - 9: All time maximum (instant not considered)
  //  * relative: boolean to switch between p.u. and absolute

  const busIndex = pfrObj.GetBusIndex(busId)
  const op = relative ? 256 : 0
  if (variable < 4) {
    return pfrObj.GetBusV(frameId, instant, instant, busIndex, variable - 1 + op)[0]
  } if (variable < 8) {
    return pfrObj.GetBusPhaseV(frameId, instant, instant, busIndex, variable - 4, op)[0]
  } if (variable === 9) {
    return pfrObj.GetBusVmin(frameId, 0, getMaxTime(pfrObj), busIndex, op)
  }
  return pfrObj.GetBusVmax(frameId, 0, getMaxTime(pfrObj), busIndex, op + 1)
}

// Function that recieve a JSON with children and return a array
function recorrerArbol (arr, firstNode, json, db, electricResults, includeFirst, instant, type,
  dataRelative, nodeDistance, nodeVoltage, caseSelected) {
  // variables that only it uses for the function
  let typeChildren
  let auxDistance
  let minVoltage
  let maxVoltage

  // Add the first row in array
  if (includeFirst) {
    minVoltage = getVoltageValue(electricResults, firstNode, caseSelected,
      instant, 9, dataRelative)
    maxVoltage = getVoltageValue(electricResults, firstNode, caseSelected,
      instant, 8, dataRelative)
    arr.push([firstNode, 0, nodeDistance[firstNode], nodeVoltage[firstNode],
      minVoltage, maxVoltage])
  }

  for (let i = 0; i < json.length; i += 1) {
    // Calculate the distance
    if (json[i].nodetype === 1) {
      nodeVoltage[json[i].name] = getVoltageValue(electricResults, json[i].name,
        caseSelected, instant, type, dataRelative)
    } else { // In the case it is not a bus: no voltage drop from parent
      nodeVoltage[json[i].name] = nodeVoltage[json[i].PARENT]
    }

    if (json[i].LINE !== -1) {
      auxDistance = db.exec(`SELECT Length FROM Line WHERE (Bus1 = ${json[i].name} and Bus2 = ${json[i].PARENT}) or (Bus1 = ${json[i].PARENT} and Bus2 = ${json[i].name})`)[0].values[0][0]
      nodeDistance[json[i].name] = auxDistance + nodeDistance[json[i].PARENT]
    } else {
      nodeDistance[json[i].name] = nodeDistance[json[i].PARENT]
      auxDistance = -1
    }

    minVoltage = getVoltageValue(electricResults, json[i].name, caseSelected,
      instant, 9, dataRelative)
    maxVoltage = getVoltageValue(electricResults, json[i].name, caseSelected,
      instant, 8, dataRelative)
    // Add row array
    arr.push([json[i].name, json[i].PARENT, nodeDistance[json[i].name],
      nodeVoltage[json[i].name], minVoltage, maxVoltage])

    // See if it have children
    typeChildren = typeof json[i].children
    if (typeChildren === 'undefined') {
      // Don't have children, finish
    } else {
      // tiene hijos, vuelvo a la funcion para recorrer la del hijo
      recorrerArbol(arr, firstNode, json[i].children, db, electricResults, false, instant, type,
        dataRelative, nodeDistance, nodeVoltage, caseSelected)
    }
  }
  // console.log(arr)
  return arr
}

// look father recursive
function buscarPadre (hijo, padre, dataArray) {
  // if equals, is father
  if (dataArray[hijo][1] === dataArray[padre][0]) {
    pfather = padre
  } else {
    buscarPadre(hijo, padre - 1, dataArray)
  }
  return pfather
}

export class PFT {
  /*
  Class for managing the creation and behavior of Voltage-Length Plot (VLP)
  Args:
    * name: id of the html element where the VLP will be injected
    * instant: id of the instant to plot
    * dataBase: sqlite database of the network
    * electricResults: PFR object to calculate variables (check assets/DigitalTwin/PFR_2)
    * t1
    * host: hostname of backend
    * port: port of backend service
    * type: selector for the variable
    * dataRelative: indicator if we are using a p.u. scale
  */
  // let myPFT = new PFT(this.name, this.case, this.substation, this.BBDD, this.electricResults,
  // this.simParams.StepCount, this.$API_HOST, this.$API_PORT, this.$HEADER_HTTP,
  // document.getElementById('typeValueChoose').value, this.data_relative);

  constructor (name, caseSelected, instant, substation, dataBase, electricResults, t1, host,
    port, protocol, type, dataRelative) {
    this.nodeVoltage = {}
    this.nodeDistance = {}
    this.dataBase = dataBase
    this.electricResults = electricResults
    this.margin = {
      top: 50, bottom: 100, left: 50, right: 50
    };
    // TODO: AUTOSCALE??
    // console.log('La resolución de tu pantalla es: '+ window.innerWidth +'x'+ window.innerHeight)
    // this.width = window.innerWidth - 100;
    // this.height = window.innerHeight - 100;
    this.width = 800
    this.height = 500
    // Be careful doing this, it can affect to other components,
    // at least try to use not a common name as 'plot'
    if ((document.getElementById('VLPlot') != null)) {
      document.getElementById('VLPlot').remove()
    }

    // Made the area for the graphic
    this.svg = d3.select(`#${name}`)
      .append('svg')
      .attr('width', this.width - this.margin.left - this.margin.right)
      .attr('height', this.height - this.margin.top - this.margin.bottom)
      .attr('viewBox', `0 0 ${this.width} ${this.height}`)
      .attr('id', 'VLPlot')

    // Tooltip object
    this.tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0)

    // promise for obtain topology of the network
    get(`${protocol}://${host}:${port}/ari/dendrogram/${substation}`)
      .then((response) => {
        // GET THE HIERARCHICAL
        this.topologyDen = response
        // Get operator offset to set p.u. or absolute magnitudes
        const op = dataRelative ? 256 : 0

        // Initialize the dictionary distance with the firts distance
        this.parentNodeId = this.topologyDen.name
        this.nodeDistance[this.parentNodeId] = 0

        const busVoltage = getVoltageValue(this.electricResults, this.topologyDen.name,
          caseSelected, instant, type, dataRelative)
        this.nodeVoltage[this.parentNodeId] = busVoltage

        // Make the array with all topology
        // FORMAT OF dataArray -> [id, id_parent, distance, voltage]
        const dataArray = recorrerArbol([], this.topologyDen.name, this.topologyDen.children,
          this.dataBase, this.electricResults, true, instant, type, dataRelative,
          this.nodeDistance, this.nodeVoltage, caseSelected)

        // CALCULATE ABSOLUTE MINIMUM AND MAXIMUM VOLTAGES AND MAXIMUM DISTANCE IN THE PLOT
        let minVoltage = 600000
        let maxVoltage = 0
        const minDist = 0
        maxDist = 0
        let thisMinVoltage
        let thisMaxVoltage
        let distance
        for (let i = 0; i < dataArray.length; i += 1) {
          distance = dataArray[i][2]
          if (!Number.isNaN((dataArray[i][4]))) {
            thisMinVoltage = dataArray[i][4]
            minVoltage = minVoltage < thisMinVoltage ? minVoltage : thisMinVoltage
          }
          if (!Number.isNaN((dataArray[i][5]))) {
            thisMaxVoltage = dataArray[i][5]
            maxVoltage = maxVoltage > thisMaxVoltage ? maxVoltage : thisMaxVoltage
          }
          maxDist = maxDist > distance ? maxDist : distance
        }
        // console.log('minVoltage', minVoltage);
        // console.log('maxVoltage', maxVoltage);

        // Made de axis in function of the data
        // Function to scale the values to plot in canvas
        this.x = d3.scaleLinear()
          .domain([minDist, maxDist])
          .range([this.margin.left, this.width - this.margin.right])

        if (dataRelative === true) {
          // Put the length that I want show in y axis
          this.y = d3.scaleLinear()
            .domain([minVoltage - 0.002, maxVoltage + 0.001])
            .range([this.height - this.margin.bottom, this.margin.top])
        } else {
          // console.log(maxVoltage);
          // Put the length that I want show in y axis
          this.y = d3.scaleLinear()
            .domain([minVoltage - 1, maxVoltage + 1])
            .range([this.height - this.margin.bottom, this.margin.top])
        }

        const xAxis = d3.axisBottom().scale(this.x).ticks(10)

        const yGrid = d3
          .axisLeft()
          .scale(this.y)
          .tickSize(-(this.width), 0, 0)
          .tickFormat('')

        // Call the functions that draw the axis
        this.svg.append('g').attr('class', 'xaxis')
          .attr('transform', `translate(0,${this.height - this.margin.bottom})`)
          .call(xAxis)
          .attr('font-size', '20px')
          .append('text')
          .text('Distance (m)')
          .attr('x', this.x(maxDist / 2))
          .attr('y', 50)
          .attr('stroke', 'white')
          .attr('font-size', '15')

        this.svg.append('g')
          .attr('class', 'yaxis')
          .attr('transform', `translate(${this.margin.left}, 0)`)
          .call(d3.axisLeft(this.y))
          .attr('font-size', '20px')
          .append('text')
          .text('Voltage (V)')
          .attr('x', -200)
          .attr('y', -90)
          .attr('stroke', 'white')
          .attr('font-size', '15')
          .attr('transform', 'rotate(-90)')

        this.svg.append('g').attr('id', 'grid').attr('transform', 'translate(50,0)').call(yGrid)

        // so far draw axis and grid
        // console.log('DataArray:', dataArray);
        this.drawEnclosure(t1, op, dataArray)
        this.drawDataGraph(dataArray)
      })
      .catch((err) => console.error(err))
  }

  drawDataGraph (dataArray) {
    // console.log('Pintar grafico', dataArray);
    const ramificaciones = this.svg.append('g')
      .attr('id', 'ramificaciones')

    // Draw legend
    if ((document.getElementById('legend') != null)) {
      document.getElementById('legend').remove()
    }

    const legend = this.svg.append('g')
      .attr('id', 'legend')
      .attr('transform', `translate(0,${this.height - this.margin.bottom})`)

    let ramas = 0
    let k = 0
    let color
    let rama
    let vpadre
    let legenx = 4
    let legeny = 73

    for (let j = 0; j < dataArray.length; j += 1) {
      // Here look the father
      // if it is the firts, it doesn't have father
      if (j !== 0) {
        // pass to buscarPadre (j,k), k the one on top, is supposed to be the father,
        // but not always, so I left until he is
        k = (j - 1)
        vpadre = buscarPadre(j, k, dataArray)
      } else {
        vpadre = j
      }

      // draw points
      // separate in branchs
      if (dataArray[vpadre][0] === this.parentNodeId) {
        if (colors[ramas] === undefined) {
          colors[ramas] = colorRGB()
        }
        color = colors[ramas]
        rama = ramificaciones.append('g')
          .attr('id', `rama${ramas}`)
          .attr('class', 'rama')
          // eslint-disable-next-line
          .on('mouseover', function () { // function(e,d)
            d3.select(this)
              .attr('class', 'mouseOver')
              .attr('style', 'stroke: rgb(255, 230, 0); stroke-width: 10px;')
          })
          // eslint-disable-next-line
          .on('mouseout', function () {
            d3.select(this)
              .attr('class', '')
              .attr('style', '')
          })

        // LEGEND
        legend.append('g').attr('id', `legendBranch${ramas}`)
        legend.append('text').text(`Br. ${ramas}`).attr('x', this.x(legenx)).attr('y', legeny)
          .attr('stroke', 'white')
          .attr('font-size', '15')
          .attr('name', `t${ramas}`)
          .attr('style', 'cursor:pointer')

        legend.append('circle').attr('cx', this.x(legenx - 10)).attr('cy', legeny - 5).attr('r', 8)
          .style('fill', color)
          .attr('class', `legendBranch${ramas}`)
          .attr('name', `c${ramas}`)

        legend.on('click', (e) => { // function(e,d)
          const id = e.target.getAttribute('name').slice(1)
          const x = document.getElementById(`rama${id}`)
          if (x.style.display === 'none') {
            x.style.display = 'block'
            branchHide.splice(parseInt(id, 10))
            if (branchHide.indexOf(parseInt(id, 10)) !== -1) {
              branchHide.splice(branchHide.indexOf(parseInt(id, 10)), 1)
            }
          } else {
            x.style.display = 'none'
            branchHide.push(parseInt(id, 10))
          }
        })

        legenx += maxDist / 9

        // Line breaks
        if (ramas === 8) {
          legenx = 4
          legeny = 90
        } else if (ramas === 16) {
          legenx = 4
          legeny = 107
        }
        ramas += 1
      }

      rama.append('circle')
        .attr('cx', () => this.x(dataArray[j][2]))
        .attr('cy', this.y(dataArray[j][3]))
        .attr('fill', color)
        .attr('r', 5)
        .attr('id', dataArray[j][0])
        .on('mouseover', (e) => {
          this.tooltip
            .transition()
            .duration(100)
            .style('opacity', 0.9)
          this.tooltip
            .html(`NODE:${e.target.getAttribute('id')}`)
            .style('left', `${e.pageX}px`)
            .style('top', `${e.pageY}px`)
        })
        .on('mouseout', () => {
          this.tooltip
            .transition()
            .duration(300)
            .style('opacity', 0)
        })

      // draw lines
      rama.append('line')
        .attr('x1', this.x(dataArray[j][2]))
        .attr('y1', this.y(dataArray[j][3]))
        .attr('x2', this.x(dataArray[vpadre][2]))
        .attr('y2', this.y(dataArray[vpadre][3]))
        .attr('class', 'line')
        .attr('stroke', color)
        .attr('stroke-width', 4)
        .attr('id', dataArray[vpadre][0])
    }
    // console.log('colors al terminar:')
    // console.log(colors)
    for (let o = 0; o < branchHide.length; o += 1) {
      document.getElementById(`rama${branchHide[o]}`).style.display = 'none'
    }
  }

  drawEnclosure (t1, op, dataArray) {
    const enclosure = this.svg.insert('g').attr('id', 'enclosure')

    // Look for minimum and maximum at each distance
    // { distance : [minVoltage, maxVoltage, parent_min, parent_max]}
    let CDist
    let vMin
    let vMax
    let parent
    let vParent
    const VDist = {}
    for (let k = 0; k < dataArray.length; k += 1) {
      CDist = dataArray[k][2]
      vMin = dataArray[k][4]
      vMax = dataArray[k][5]
      parent = dataArray[k][1]
      if (VDist[CDist] === undefined) {
        if (parent !== 0) {
          vParent = buscarPadre(k, k - 1, dataArray)
        } else {
          vParent = -1
        }
        VDist[CDist] = [vMin, vMax, vParent, vParent]
      } else {
        // Check minimum
        if (VDist[CDist][0] > vMin) {
          VDist[CDist][0] = vMin
          VDist[CDist][2] = buscarPadre(k, k - 1, dataArray)
        }
        // Check maximum
        if (VDist[CDist][1] < vMax) {
          VDist[CDist][1] = vMax
          VDist[CDist][3] = buscarPadre(k, k - 1, dataArray)
        }
      }
    }

    // console.log('THE ENCLOSURE');
    // console.log(VDist);

    const minMaxArrayVolDist = Object.entries(VDist).map((arr2) => [parseFloat(arr2[0]), arr2[1][0],
      arr2[1][1], arr2[1][2], arr2[1][3]]).sort((a, b) => a[0] - b[0])
    // console.log('Min voltages per distance');
    // console.log(minMaxArrayVolDist);
    // console.log('DATA ARRAY');
    // console.log(dataArray);

    const finalEnclosureMin = []
    const finalEnclosureMax = []
    let currentX
    let currentYMin
    let currentYMax
    let forwardX
    let forwardYMin
    let forwardYMax
    let forwardParentX
    let forwardParentYMin
    let forwardParentYMax
    let forwardParent
    let interpYMin
    let interpYMax
    let stopMax
    let stopMin // boolean indicators to stop looking for max and min
    for (let i = 0; i < minMaxArrayVolDist.length; i += 1) {
      currentX = minMaxArrayVolDist[i][0]
      currentYMin = minMaxArrayVolDist[i][1]
      currentYMax = minMaxArrayVolDist[i][2]
      stopMin = false
      stopMax = false
      for (let j = 0; j < minMaxArrayVolDist.length; j += 1) {
        forwardX = minMaxArrayVolDist[j][0]
        if (forwardX > currentX) {
          forwardParent = minMaxArrayVolDist[j][3]
          forwardParentX = dataArray[forwardParent][2]
          if (forwardParentX < currentX && !stopMin) {
            forwardParentYMin = dataArray[forwardParent][4]
            forwardYMin = minMaxArrayVolDist[j][1]
            interpYMin = forwardParentYMin + (currentX - forwardParentX) *
            ((forwardYMin - forwardParentYMin) / (forwardX - forwardParentX))
            if (interpYMin <= currentYMin) {
              stopMin = true
            }
          }
          forwardParent = minMaxArrayVolDist[j][4]
          forwardParentX = dataArray[forwardParent][2]
          if (forwardParentX < currentX && !stopMax) {
            forwardParentYMax = dataArray[forwardParent][5]
            forwardYMax = minMaxArrayVolDist[j][2]
            interpYMax = forwardParentYMax + (currentX - forwardParentX) *
            ((forwardYMax - forwardParentYMax) / (forwardX - forwardParentX))
            if (interpYMax >= currentYMax) {
              stopMax = true
            }
          }
          if (stopMax && stopMin) {
            break
          }
        }
        if (j === minMaxArrayVolDist.length - 1) {
          if (!stopMin) {
            finalEnclosureMin.push([currentX, currentYMin])
          }
          if (!stopMax) {
            finalEnclosureMax.push([currentX, currentYMax])
          }
        }
      }
    }
    // console.log('finalEnclosureMin');
    // console.log(finalEnclosureMin);

    // console.log('finalEnclosureMax');
    // console.log(finalEnclosureMax);
    enclosure.insert('path')
      .attr('d', this.enclosure2d(finalEnclosureMax, finalEnclosureMin))
      .attr('stroke', 'none')
      .attr('fill', 'white')
      .attr('opacity', '0.2')
  }

  updateGraph (name, caseSelected, instant, type, dataRelative) {
    this.svg.select('#ramificaciones').remove()
    // Initialize the dictionary distance with the firts distance
    this.parentNodeId = this.topologyDen.name
    this.nodeDistance[this.parentNodeId] = 0

    const busVoltage = getVoltageValue(this.electricResults, this.topologyDen.name,
      caseSelected, instant, type, dataRelative)
    this.nodeVoltage[this.parentNodeId] = busVoltage
    const dataArray = recorrerArbol([], this.topologyDen.name, this.topologyDen.children,
      this.dataBase, this.electricResults, true, instant, type, dataRelative,
      this.nodeDistance, this.nodeVoltage, caseSelected)
    this.drawDataGraph(dataArray)
  }

  enclosure2d (arr1, arr2) {
    // 'MX1,X1LX2,Y2'
    let d = 'M'
    for (let k = 0; k < arr1.length; k += 1) {
      d = `${d + this.x(arr1[k][0])},${this.y(arr1[k][1])}L`
    }
    const arrL2 = arr2.length
    for (let k = 0; k < arrL2; k += 1) {
      if (k < arrL2 - 1) {
        d = `${d + this.x(arr2[arrL2 - k - 1][0])},${this.y(arr2[arrL2 - k - 1][1])}L`
      } else {
        d = `${d + this.x(arr2[arrL2 - k - 1][0])},${this.y(arr2[arrL2 - k - 1][1])}`
      }
    }
    return d
  }
}
