<template>
  <div>
    <v-expansion-panels
      v-model="panel"
      dark
      accordion
      multiple
    >
      <v-expansion-panel :key="0">
        <v-expansion-panel-header :hide-actions="false">
          {{ $t('grid_panel.general_information') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content style="border 1px solid #fff">
          <v-row style="margin-top:0px">
            <v-col
              cols="12"
              sm="4"
            >
              ID:
            </v-col>
            <v-col
              cols="12"
              sm="8"
            >
              {{ info.code }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="4"
            >
              {{ $t('cable') }}
            </v-col>
            <v-col
              cols="12"
              sm="8"
            >
              {{ info.cableType }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="4"
            >
              {{ $t('length') }}:
            </v-col>
            <v-col
              cols="12"
              sm="8"
            >
              {{ info.length }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="4"
            >
              {{ $t('grid_panel.max_current') }}:
            </v-col>
            <v-col
              cols="12"
              sm="8"
            >
              {{ info.maxCurrent }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="4"
            >
              {{ $t('voltage') }}
            </v-col>
            <v-col
              cols="12"
              sm="8"
            >
              {{ info.voltage }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="4"
            >
              {{ $t('installation_date') }}
            </v-col>
            <v-col
              cols="12"
              sm="8"
            >
              {{ info.installation }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="4"
            >
              {{ $t('grid.network') }}:
            </v-col>
            <v-col
              cols="12"
              sm="8"
            >
              {{ network }}
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>

export default {
  name: 'LineDetails',
  props: {
    info: {
      type: Object,
      default: null
    },
    network: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      panel: [0]
    }
  }
}
</script>
<style scoped>
.v-expansion-panel-content{
    font-size:14px
}
.col-12{
    padding:1px 15px !important;
    text-align:left;
}
div.v-expansion-panel-content__wrap{
  margin:20px 0;
}
.hr-separator-dark{
  margin: 12px 0;
  border-top: 0.5px solid #444;
  border-bottom: none;
  border-left: none;
  border-right:none;
}
</style>
