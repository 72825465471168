<template>
  <div>
    <v-row id="cp-upload-inputs">
      <v-col
        cols="12"
        class="ml-20"
      >
        <v-autocomplete
          v-model="activeValue"
          dark
          dense
          :items="activeHeaders"
          :label="$t('dt.active')"
        />
        <v-autocomplete
          v-model="reactiveValue"
          dark
          dense
          :items="reactiveHeaders"
          :label="$t('dt.reactive')"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import digitalTwinForm from '@/mixins/digitalTwinForm'

export default {
  name: 'CustomProfileUploadForm',
  mixins: [digitalTwinForm],
  props: {
    headers: {
      type: Array,
      default: () => []
    },
    content: {
      type: Object,
      default: null
    },
    parsed: {
      type: Boolean,
      default: false
    },
    fileTitle: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      formReady: false,
      activeValue: null,
      reactiveValue: null,
      minValue: -51000,
      maxValue: 51000,
      DEVICE: 'CP',
      values: {
        ref: this.fileTitle.replace(/ /g, ''),
        P: [],
        Q: []
      }
    }
  },
  computed: {
    activeHeaders () {
      if (!this.headers) {
        return []
      }
      return this.headers.filter(header => header !== this.reactiveValue)
    },
    reactiveHeaders () {
      if (!this.headers) {
        return []
      }
      return this.headers.filter(header => header !== this.activeValue)
    }
  },
  watch: {
    activeValue () {
      this.checkFormReady()
    },
    reactiveValue () {
      this.checkFormReady()
    }
  },
  methods: {
    checkFormReady () {
      const activeArrayValues = []
      const reactiveArrayValues = []
      // 1. Has to have values. There is no need to be same stepCount than number of records
      if (this.values.activeValue !== null && this.values.reactiveValue !== null) {
      // 2. Selected positions must have values between set minValue and maxValue
        const activePosition = this.headers.indexOf(this.activeValue)
        const reactivePosition = this.headers.indexOf(this.reactiveValue)

        this.formReady = false

        for (let i = 1; i < this.content.data.length; i++) {
          const activeFieldValue = parseFloat(this.content.data[i][activePosition])
          const reactiveFieldValue = parseFloat(this.content.data[i][reactivePosition])

          if (activeFieldValue > this.minValue && activeFieldValue < this.maxValue && reactiveFieldValue > this.minValue && reactiveFieldValue < this.maxValue) {
          // 3. Give values to profile to P and Q for each connection point
            activeArrayValues.push(activeFieldValue)
            reactiveArrayValues.push(reactiveFieldValue)
            this.formReady = true
          } else {
            this.resetPQ()
            this.formReady = false
            break
          }
        }
      } else {
        this.resetPQ()
        this.formReady = false
      }

      // 4. Send values to the form parent
      if (this.formReady) {
        this.values.P = activeArrayValues
        this.values.Q = reactiveArrayValues
        this.$emit('change', this.values)
      }
    },
    resetPQ () {
      this.values.P = []
      this.values.Q = []
    }
  }
}
</script>
<style scoped>
.p-0 {
  padding: 0 !important
}
.ml-20 {
  margin-left:20px
}
</style>
