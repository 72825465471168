<template>
  <v-card
    dark
    style="min-height:91.5vh"
  >
    <v-card-title
      class="text-center"
      style="padding-bottom:0px; margin-left:20px; font-size:30px; color: #f4c020"
    >
      {{ DTProject.name }}
    </v-card-title>
    <v-card-title
      class="text-center"
      style="padding-top: 0px; padding-bottom:0px; margin-left:20px; font-size:18px; color: #f4c020"
    >
      {{ typePenetrationTitle }}
    </v-card-title>
    <v-card-title
      class="text-center"
      style="padding-top: 0px; padding-bottom:0px; margin-left:20px; font-size:18px; color: #f4c020"
    >
      {{ $t('grid.networks') }}: {{ networks }}
    </v-card-title>
    <v-row style="margin:5px 20px 0px 20px">
      <v-col cols="7">
        <v-card>
          <MontecarloTable
            :table-content="tableContent"
            @clickViolation="showGraphResults"
          />
        </v-card>
      </v-col>
      <v-col
        v-if="digitalTwinResults"
        cols="5"
      >
        <v-card>
          <MontecarloPlot
            :x="x"
            :y="y"
            :title="title"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

import { mapState } from 'vuex'
import MontecarloTable from '@/components/DigitalTwin/Montecarlo/MontecarloTable.vue'
import MontecarloPlot from '@/components/DigitalTwin/Montecarlo/MontecarloPlot.vue'

export default {
  name: 'DigitalTwinPlanning',
  components: {
    MontecarloTable,
    MontecarloPlot
  },
  data () {
    return {
      violation_vector: [0, 1, 2, 3, 4, 5, 6, 7],
      mask: 255,
      x: undefined,
      y: undefined,
      title: undefined
    }
  },
  computed: {
    ...mapState({
      digitalTwinResults: (state) => state.DTResults,
      planningType: (state) => state.planningType,
      DTProject: (state) => state.DTProject
    }),
    violationMask () {
      return [
        {
          name: this.$t('planning.phA_undervoltage'),
          type: 0,
          value: 1
        },
        {
          name: this.$t('planning.phB_undervoltage'),
          type: 1,
          value: 2
        },
        {
          name: this.$t('planning.phC_undervoltage'),
          type: 2,
          value: 4
        },
        {
          name: this.$t('planning.phA_overvoltage'),
          type: 3,
          value: 8
        },
        {
          name: this.$t('planning.phB_overvoltage'),
          type: 4,
          value: 16
        },
        {
          name: this.$t('planning.phC_overvoltage'),
          type: 5,
          value: 32
        },
        {
          name: this.$t('planning.voltage_negative_sequence'),
          type: 6,
          value: 64
        },
        {
          name: this.$t('planning.voltage_zero_sequence'),
          type: 7,
          value: 128
        },
        {
          name: this.$t('planning.phA_overcurrent'),
          type: 8,
          value: 256
        },
        {
          name: this.$t('planning.phB_overcurrent'),
          type: 9,
          value: 512
        },
        {
          name: this.$t('planning.phC_overcurrent'),
          type: 10,
          value: 1024
        },
        {
          name: this.$t('planning.phN_overcurrent'),
          type: 11,
          value: 2048
        },
        {
          name: this.$t('planning.overloading_transformer'),
          type: 12,
          value: 4096
        }
      ]
    },
    typePenetrationTitle () {
      const customPenetrationText = this.$t('dt.penetration')[0].toUpperCase() + this.$t('dt.penetration').slice(1)
      return `${this.$t('type') + ': ' + this.device + ' ' + customPenetrationText}`
    },
    tableContent () {
      const tableContentResults = []
      // let acumulative = 0;
      Object.values(this.violationMask).forEach((violation) => {
        const violationResults = this.digitalTwinResults.Results.GetViolation(
          0, 24, violation.value
        )
        // acumulative += violation.value;
        const violationResultsRound = []
        Object.values(violationResults).forEach((result) => {
          violationResultsRound.push(this.roundToOne(result))
        })
        tableContentResults.push({
          name: violation.name,
          type: violation.type,
          values: violationResultsRound
        })
      })
      return tableContentResults
    },
    device () {
      if (this.planningType === 'EV') {
        return 'EV'
      }
      return 'PV'
    },
    networks () {
      let networksString = ''
      Object.values(this.DTProject.networks).forEach((network) => {
        networksString += network
        networksString += ', '
      })
      return networksString.slice(0, -2)
    }
  },
  watch: {
    violation_vector () {
      this.getTableContent()
      let maskValue = 0
      for (let i = 0; i < this.violation_vector.length; i += 1) {
        const id = this.violation_vector[i]
        maskValue += this.violationMask[id]
      }
      this.mask = maskValue
      this.y = this.digitalTwinResults.Results.GetViolation(0, 24, this.mask)
    }
  },
  mounted () {
    this.y = this.digitalTwinResults.Results.GetViolation(0, 24, 1)
    if (this.planningType === 'EV') {
      this.x = this.digitalTwinResults.Results.TestPercentVec()
    } else {
      this.x = this.digitalTwinResults.Results.TestPowerVec()
    }
  },
  methods: {
    roundToThree (num) {
      return +(`${Math.round(`${num}e+3`)}e-3`)
    },
    roundToOne (num) {
      return +(`${Math.round(`${num}e+1`)}e-1`)
    },
    showGraphResults (types) {
      // get the mamsk value according to type
      if (this.planningType === 'EV') {
        this.x = this.digitalTwinResults.Results.TestPercentVec()
      } else {
        this.x = this.digitalTwinResults.Results.TestPowerVec()
      }
      let mask = 0
      Object.values(types).forEach((type) => {
        Object.values(this.violationMask).forEach((violation) => {
          if (violation.type === type) {
            mask += violation.value
          }
        })
      })
      this.y = this.digitalTwinResults.Results.GetViolation(0, 24, mask)
    }
  }
}

</script>

<style scoped>
.v-btn {
  text-transform: unset !important;
}
</style>
