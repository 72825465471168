<template>
  <v-app>
    <DtPanelToolbar
      :type-element="typeElement"
      :id-element-to-show="idElementToShow.toString()"
      :panel-only-title="panelOnlyTitle"
      :transformers-list="transformersList"
      @updateToolbar="updateToolbar"
      @updateStation="updateStation"
    />
    <splitpanes
      horizontal
      @resized="setPanelHeight(62)"
    >
      <pane
        id="kpiPanel"
        size="53"
        class="dt-panel bg-secondary"
      >
        <v-card
          class="panel-container"
        >
          <v-card-title
            class="m-0 p-0 pl-2 bg-secondary text-primary height-36"
          >
            KPIs
          </v-card-title>
          <!-- Tabs elements -->
          <DtPanelKpiTabs
            v-if="!panelOnlyTitle"
            :panel-height="panelHeight"
            :sim-results="simResults"
            :sim-headers="simHeaders"
            :table-height="panelHeight"
            :pv-list="pvList"
            :ev-list="evList"
            :hp-list="hpList"
            :load-scale-list="loadScaleList"
          />
          <h3
            v-else
            class="mt-5"
          >
            {{ $t('no_info_available') }}
          </h3>
        </v-card>
      </pane>
      <pane
        class="dt-panel bg-secondary"
      >
        <v-card
          class="panel-container"
        >
          <v-card-title
            class="m-0 p-0 pl-2 bg-secondary text-primary height-36"
          >
            {{ $t('plots') }}
          </v-card-title>
          <DtPanelPlotTabs
            v-if="!panelOnlyTitle"
            :type-value="typeValue"
            :sampling-period="samplingPeriod"
            :data-plot-v="dataPlotV"
            :data-plot-i="dataPlotI"
            :data-plot-p-s="dataPlotPS"
            :data-plot-p-p="dataPlotPP"
            :data-plot-p-q="dataPlotPQ"
            :data-plot-p-l="dataPlotPL"
            :data-plot-p-u-f-c="dataPlotPUFC"
            :data-plot-p-u-f-v="dataPlotPUFV"
            :data-plot="dataPlot"
            :data-plot-l-c-p="dataPlotLCP"
            :digital-twin-results="digitalTwinResults"
            :id-station="idStation"
            :level-voltage="levelVoltage"
            :type-element="typeElement"
          />
          <h3
            v-else
            class="mt-5"
          >
            {{ $t('no_info_available') }}
          </h3>
        </v-card>
      </pane>
    </splitpanes>
  </v-app>
</template>

<script>
import { Splitpanes, Pane } from 'splitpanes'
// Components
import DtPanelToolbar from '@/components/DigitalTwin/DtPanelToolbar.vue'
import DtPanelKpiTabs from '@/components/DigitalTwin/DtPanelKpiTabs.vue'
import DtPanelPlotTabs from '@/components/DigitalTwin/DtPanelPlotTabs.vue'
// Icons
import { mdiTableLarge, mdiLightningBoltCircle, mdiFormatListBulleted } from '@mdi/js'

export default {
  name: 'DigitalTwinPanel',
  components: {
    Splitpanes,
    Pane,
    DtPanelToolbar,
    DtPanelKpiTabs,
    DtPanelPlotTabs
  },
  props: {
    idElement: {
      type: String,
      default: null
    },
    idElementToShow: {
      type: String,
      default: null
    },
    idStation: {
      type: Number,
      default: null
    },
    panelOnlyTitle: {
      type: Boolean,
      default: false
    },
    levelVoltage: {
      type: Number,
      default: null
    },
    typeElement: {
      type: String,
      default: null
    },
    station: {
      type: String,
      default: null
    },
    stations: {
      type: Array,
      default: null
    },
    simResults: {
      type: Array,
      default: null
    },
    pvList: {
      type: Array,
      default: null
    },
    evList: {
      type: Array,
      default: null
    },
    hpList: {
      type: Array,
      default: null
    },
    loadScaleList: {
      type: Number,
      default: null
    },
    simHeaders: {
      type: Array,
      default: null
    },
    dataPlot: {
      type: Array,
      default: null
    },
    dataPlotLCP: {
      type: Array,
      default: null
    },
    dataPlotV: {
      type: Array,
      default: null
    },
    dataPlotI: {
      type: Array,
      default: null
    },
    dataPlotPS: {
      type: Array,
      default: null
    },
    dataPlotPP: {
      type: Array,
      default: null
    },
    dataPlotPQ: {
      type: Array,
      default: null
    },
    dataPlotPL: {
      type: Array,
      default: null
    },
    dataPlotPUFC: {
      type: Float32Array,
      default: null
    },
    dataPlotPUFV: {
      type: Float32Array,
      default: null
    },
    typeValue: {
      type: Number,
      default: null
    },
    samplingPeriod: {
      type: Number,
      default: null
    },
    digitalTwinResults: {
      type: Object,
      default: null
    },
    initDate: {
      type: Date,
      default: null
    },
    endDate: {
      type: Date,
      default: null
    },
    transformersList: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      tableIcon: mdiTableLarge,
      powerIcon: mdiLightningBoltCircle,
      listIcon: mdiFormatListBulleted,
      panelHeight: null
    }
  },
  mounted () {
    this.setPanelHeight(32)
  },
  methods: {
    setPanelHeight (margin) {
      const height = document.getElementById('kpiPanel').clientHeight - margin
      this.panelHeight = `${height}px`
    },
    resizePanel () {
      this.$root.$emit('resizePlotly')
    },
    updateToolbar (var1, var2) {
      this.$emit('updatePanel', var1, var2)
    },
    updateStation (idTransformer) {
      this.$emit('updateStation', idTransformer)
    }
  }
}

</script>

<!-- Scoped was removed here to allow overriding of styles,
specially needed for the elements in the header -->
<style>
/* To limit the padding of the content inside the tabs to take benefit of more space */
div.v-card__text {
  padding: 4px;
}

.choose-date .v-text-field__slot input {
  color: #f4c020 !important;
}
.panel-container {
  margin:5px 2px 0 2px;
  padding-top: 0px;
  flex:1;
}

.dt-panel {
  overflow-y:auto;
  display: flex;
}

.tabs {
  overflow-y: auto;
}

.v-tab {
  min-width: 59px !important;
  max-width: 59px !important;
  margin-right: -14px !important;
  margin-left: -5px !important;
}
/* Horizontal line beyond Element dates in the header */
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #f4c020 !important;
}
.theme--dark.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #f4c020 !important;
}
.splitpanes {
  background-color: #272727;
}

.height-36 {
  height:36px;
}

</style>
