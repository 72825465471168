import { plexiRequest } from '.'
import { get } from '@/assets/queries'

export async function getBufferResults (host, station) {
  const response = await get(`${host}/hosting-capacity/results?station=${station}`, undefined, undefined, true)
  const binaryResponse = await response.arrayBuffer()
  return new Float32Array(binaryResponse)
}

export async function getDBResults (host, station) {
  const response = await get(`${host}/hosting-capacity/db?station=${station}`, undefined, undefined, true)
  const binaryResponse = await response.arrayBuffer()
  return new Uint8Array(binaryResponse)
}

export const manageHC = async () =>
  plexiRequest({ url: 'hosting-capacity/manage' })

export const getHC = async (station) =>
  station && plexiRequest({ url: `hosting-capacity/manage?station=${station}` })

export const getHCForAllStations = async (stationIds) => {
  const stationsUrl = stationIds.join('&station=')
  return (stationIds && stationsUrl) && plexiRequest({ url: `hosting-capacity/manage?station=${stationsUrl}` })
    .then(data => {
      const { stationsHCInfo } = data
      if (!stationsHCInfo) return data
      return []
    })
}

/** Reservation */
export const addReservation = async (body) =>
  body &&
  plexiRequest({
    url: 'hosting-capacity/reserve',
    method: 'post',
    data: body
  })

export const getReservation = async (type, id) =>
  type && id &&
  plexiRequest({ url: `hosting-capacity/reserve?${type}=${id}` })

export const removeReservation = async (id) =>
  id &&
  plexiRequest({
    url: 'hosting-capacity/reserve',
    method: 'delete',
    data: id
  })
