<template>
  <v-dialog
    v-model="showForm"
    style="z-index:501"
    max-width="450"
    overlay-opacity="0.5"
  >
    <v-card elevation="10">
      <v-form
        ref="CGP_NewForm"
        v-model="valid"
        lazy-validation
      >
        <v-card-title class="text-h5">
          {{ $t('new_connections.new_connection_point') }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="values.Pmax"
                  :rules="VALIDATIONS.Pmax"
                  :label="$t('new_connections.max_real_p')"
                  type="number"
                  dense
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <p v-if="lineFull">
          {{ $t('new_connections.line_full') }}
        </p>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#f4c020"
            text
            @click="closeForm"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            v-if="payload.edit"
            id="edit"
            :disabled="!valid"
            text
            @click="editDevice"
          >
            {{ $t('edit') }}
          </v-btn>
          <v-btn
            v-if="payload.edit"
            id="delete"
            text
            @click="deleteCGP"
          >
            {{ $t('delete') }}
          </v-btn>
          <v-btn
            v-else
            :disabled="!valid"
            data-cy="dt-add-ev"
            color="#f4c020"
            text
            @click="addCGP"
          >
            {{ $t('add') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import digitalTwinForm from '@/mixins/digitalTwinForm'
import dtNewElementMixin from '@/mixins/dtNewElementMixin'
import proj4 from 'proj4'
import { mapState } from 'vuex'

export default {
  name: 'DTNewConnectionPointForm',

  mixins: [digitalTwinForm, dtNewElementMixin],

  data () {
    return {
      DEVICE: 'CGP_New',
      values: {
        ref: 'NewCGP', // Change
        Pmax: 50000,
        mode: 1, // Fixed value, in the map only appears when mode is 1
        Node: null,
        CGP: null
      },
      connectionPoint: {
        X: null,
        Y: null
      },
      lineFull: false
    }
  },
  computed: {
    ...mapState({
      contextMenuElement: ({ contextMenuElement }) => contextMenuElement.info,
      DTResults: ({ DTResults }) => DTResults,
      DTChanges: (state) => state.DTChanges,
      markers: (state) => state.markers,
      click: (state) => state.click
    })
  },
  methods: {
    addCGP () {
      const lineIndex = this.DTResults.Results.GetLineIndex(this.contextMenuElement.ID)
      const { busIdSource, busIdDestiny, busSrcX, busSrcY, busDstX, busDstY } = this.getLineBuses(lineIndex)
      const lineOccupiedConnectionPoints = this.getLineOccupiedConnectionPoints()
      let whereToDraw = null

      // Draw CGP. If there are two, there aren't free connection points. If there are cero, both of them are free
      if (lineOccupiedConnectionPoints.length === 1) {
        whereToDraw = this.getCoordinatesFreeConnectionPoint([busSrcX, busSrcY, busIdSource], [busDstX, busDstY, busIdDestiny], lineOccupiedConnectionPoints[0])
        this.drawConnectionPoint(whereToDraw)
        this.closeForm()
      } else if (lineOccupiedConnectionPoints.length === 0) {
        whereToDraw = this.getNextCoordinatesFreeConnectionPoint([busSrcX, busSrcY, busIdSource], [busDstX, busDstY, busIdDestiny])
        this.drawConnectionPoint(whereToDraw)
        this.closeForm()
      } else {
        this.lineFull = true
      }
    },
    getCoordinatesFreeConnectionPoint (busSrc, busDst, lineOccupiedConnectionPoint) {
      const buses = [busSrc, busDst]
      const coordinatesFreeConnectionPoint = buses.filter((bus) => {
        return (Math.sqrt(Math.pow(bus[0] - lineOccupiedConnectionPoint.X, 2) + Math.pow(bus[1] - lineOccupiedConnectionPoint.Y, 2)) > 1)
      })

      return coordinatesFreeConnectionPoint[0]
    },
    getNextCoordinatesFreeConnectionPoint (busSrc, busDst) {
      const clickLat = this.click.lat
      const clickLng = this.click.lng
      // Convert X and Y into Lat and Lng
      const srcLatLng = proj4(this.$sessionStorage.projectCoordSystem, this.$WGS84, [busSrc[0], busSrc[1]])
      const dstLatLng = proj4(this.$sessionStorage.projectCoordSystem, this.$WGS84, [busDst[0], busDst[1]])

      const distanceSrc = this.calculateDistance(clickLat, clickLng, srcLatLng[1], srcLatLng[0])
      const distanceDst = this.calculateDistance(clickLat, clickLng, dstLatLng[1], dstLatLng[0])

      return (distanceSrc < distanceDst) ? busSrc : busDst
    },
    drawConnectionPoint (whereToDraw) {
      // whereToDraw 0.coordinate X 1.coordinate Y 2.Node ID
      this.values.CGP = this.createNewConnectionPointId()
      this.values.ref = 'NewCGP' + whereToDraw[2]
      this.values.Node = whereToDraw[2]
      this.values.Pmax = parseInt(this.values.Pmax)
      this.connectionPoint.X = whereToDraw[0]
      this.connectionPoint.Y = whereToDraw[1]
      this.addDevice()
    },
    createNewConnectionPointId () {
      let newId = null

      if (this.DTChanges.CGP_New.CGP.length > 0) {
        const parsedArray = this.DTChanges.CGP_New.CGP.map(Number)
        const filteredArray = parsedArray.filter((item) => !isNaN(item) && typeof item === 'number')
        const max = Math.max(...filteredArray)
        newId = max + 1
      } else {
        const parsedArray = this.DTResults.Results.CGP_ID.map(Number)
        const filteredArray = parsedArray.filter((item) => !isNaN(item) && typeof item === 'number')
        const max = Math.max(...filteredArray)
        newId = max + 1
      }
      return newId
    },
    // Haversine fórmula to calculate distance beetween two points with latitide and longitude
    calculateDistance (latitude1, longitude1, latitude2, longitude2) {
      // Earth's radius in meters
      const earthRadius = 6371000 // 6,371 km

      // Convert latitudes and longitudes from degrees to radians
      const lat1Rad = (latitude1 * Math.PI) / 180
      const lat2Rad = (latitude2 * Math.PI) / 180
      const lon1Rad = (longitude1 * Math.PI) / 180
      const lon2Rad = (longitude2 * Math.PI) / 180

      // Difference in latitudes and longitudes
      const dLat = lat2Rad - lat1Rad
      const dLon = lon2Rad - lon1Rad

      // Haversine formula
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

      // Distance in meters
      const distance = earthRadius * c

      return distance
    },
    deleteCGP () {
      // Delete all DTChanges related to this new CGP
      Object.entries(this.DTChanges).forEach((changeType) => { // HP, PV, CP...
        changeType.forEach((values) => {
          if (values.CGP) {
            const position = values.CGP.indexOf(this.payload.changes.CGP)
            if (position >= 0) { // This CGP found in this kind of change
              Object.entries(values).forEach((val) => { // ndevs, CGP, ...
                // Delete element from DTChanges using position
                val[1] = val[1].splice(position, 1)
              })
            }
          } else if (values.Node) {
            // ESS
            const position = values.Node.indexOf(this.payload.changes.Node)
            if (position >= 0) { // This Node found in this kind of change
              Object.entries(values).forEach((val) => {
                // Delete element from DTChanges using position
                val[1] = val[1].splice(position, 1)
              })
            }
          }
        })
      })

      // Delete Markers from this CGP_New
      const markersResult = this.markers.filter((marker) => {
        return marker.element.ID !== this.payload.changes.CGP
      })
      this.$store.dispatch('setElement', { path: 'markers', value: markersResult })

      this.closeForm()
    }

  }

}
</script>
