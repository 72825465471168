import { datadogRum } from '@datadog/browser-rum'

const isDatadogActive = (process.env.VUE_APP_IS_DATADOG_ACTIVE === 'true')

if (isDatadogActive) {

  const tracingUrlsStr = process.env.ALLOWED_TRACING_URLS || '';
  const allowedTracingUrls = tracingUrlsStr.split(',').map(url => url.trim()).filter(url => url);

  datadogRum.init({
    applicationId: process.env.VUE_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
    site: process.env.VUE_APP_DATADOG_SITE,
    service: process.env.VUE_APP_DATADOG_SERVICE,
    env: process.env.VUE_APP_DATADOG_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: Number(process.env.VUE_APP_DATADOG_SESSION_SAMPLE_RATE),
    sessionReplaySampleRate: Number(process.env.VUE_APP_DATADOG_SESSION_REPLAY_SAMPLE_RATE),
    trackUserInteractions: (process.env.VUE_APP_DATADOG_TRACK_USER_INTERACTIONS === 'true'),
    trackResources: (process.env.VUE_APP_DATADOG_TRACK_RESOURCES === 'true'),
    trackLongTasks: (process.env.VUE_APP_DATADOG_TRACK_LONG_TASKS === 'true'),
    trackFrustrations: (process.env.VUE_APP_DATADOG_TRACK_FRUSTRATIONS === 'true'),
    defaultPrivacyLevel: process.env.VUE_APP_DATADOG_DEFAULT_PRIVACY_LEVEL,
    allowedTracingUrls: allowedTracingUrls
  })
  datadogRum.startSessionReplayRecording()
}
