<template>
  <div>
    <div
      v-for="(group, index) in groups"
      :key="index"
    >
      <VAutocomplete
        v-if="!group.hidden"
        :ref="`autocomplete-${index}`"
        v-model="options[index]"
        :placeholder="`${entity} ${$t('grid_set.area_level')} ${index + 1}`"
        :items="group.options"
        item-text="NAME"
        return-object
        clearable
        :dark="dark"
        :dense="dense"
        @click:clear="resetLevels(group)"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'CMSelectGroup',

  props: {
    source: {
      type: Array,
      default: () => []
    },

    depth: {
      type: Number,
      default: null
    },

    itemChildren: {
      type: String,
      default: 'children'
    },

    dense: {
      type: Boolean,
      default: true
    },

    dark: {
      type: Boolean,
      default: true
    },

    storeKey: {
      type: String,
      default: ''
    },

    preload: {
      type: Array,
      default: null
    },

    isMeasurements: {
      type: Boolean,
      default: false
    },

    entity: {
      type: String,
      default: 'Zone'
    }
  },

  data: () => ({
    groups: [],
    options: []
  }),

  watch: {
    options (val) {
      if (val.length) {
        const option = val.at(-1)

        if (option) {
          const { LEVEL: level, ID: id } = option
          const { options: nextGroup = [] } = this.groups[level - 1] || {}
          const { [this.itemChildren]: nextOptions } =
            nextGroup.find((area) => area.ID === id) || {}
          const hasDuplicates = this.verifyDuplicates(level, val)

          if (nextOptions?.length && this.groups[level]) {
            this.groups[level] = {
              hidden: !nextOptions.length,
              options: nextOptions
            }
          }

          if (val.length > this.depth || hasDuplicates) {
            const justPreviousLevels = val.filter((area) => area.LEVEL < level)

            this.options = [...justPreviousLevels, option]
            this.updateGroups(level)
          }

          this.storeOptions()
        } else {
          const notFalsy = val.filter(Boolean)
          this.options = notFalsy
        }

        this.$emit('updateModel', { value: option })
      }
    }
  },

  mounted () {
    this.setGroupsOptions()
  },

  methods: {
    setGroupsOptions () {
      this.groups = this.getLevels({ root: this.source })

      if (this.preload) {
        Vue.set(this, 'options', this.preload)

        this.groups = this.groups.map((group, index) => ({
          ...group,
          hidden: index > this.options.length - 1
        }))

        Vue.set(this, 'options', this.preload)
      }
    },

    verifyDuplicates (level, options = []) {
      return options.filter((area) => area.LEVEL === level).length > 1
    },

    getLevels ({ root, level = 1, levels = [] }) {
      const target = root.filter((x) => x.LEVEL === level) || []
      const options = { current: [], next: [] }

      if (!target.length) return

      target.sort((a, b) => {
        return a.NAME.localeCompare(b.NAME)
      })

      target.forEach((item) => {
        options.current = [...options.current, item]
        options.next = [...options.next, item[this.itemChildren]]
      })

      levels.push({
        options: options.current,
        hidden: levels.length
      })

      levels.forEach((item) => {
        item.options.forEach((opt) => {
          opt.SUBAREAS.sort((a, b) => {
            return a.NAME.localeCompare(b.NAME)
          })
        })
      })

      const nextLevel = level + 1
      const isFinalLevel = nextLevel > this.depth

      return isFinalLevel && options.next.length
        ? levels.flat()
        : this.getLevels({
          root: options.next.flat(),
          level: nextLevel,
          levels
        })
    },

    storeOptions () {
      if (this.storeKey) {
        this.$store.dispatch('setElement', {
          path: this.storeKey,
          value: this.options
        })

        sessionStorage[this.storeKey] = JSON.stringify(this.options)
      }
    },

    resetLevels ({ options }) {
      const level = options[0].LEVEL
      const levelsToReset = new Array(this.depth)

      this.options = this.options.filter((area) => area.LEVEL < level)

      levelsToReset.forEach((_, index) => {
        const target = this.$refs[`autocomplete-${index}`] || []
        const notPreviousLevels = index + 1 >= level

        if (target.length && notPreviousLevels) {
          target[0].reset()
        }
      })

      this.updateGroups(level)
    },

    updateGroups (level) {
      this.groups = this.groups.map((group) => {
        const [{ LEVEL: selfLevel }] = group.options

        return {
          ...group,
          hidden: selfLevel > level
        }
      })
    }
  }
}
</script>
