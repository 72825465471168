<template>
  <div
    id="timeSlider"
    class="vlp-timeSlider"
  >
    <v-col class="text-left">
      <span v-text="dateHour" />
      <span> h</span>
    </v-col>
    <v-slider
      v-model="instant"
      class="vlp-slider"
      color="#f4c020"
      track-color="#f4c020"
      :min="0"
      :max="steps"
      @input="changeInstant"
    >
      >
    </v-slider>
  </div>
</template>
<script>
export default {
  name: 'TimeSlider',
  props: {
    init: {
      type: Number,
      required: true
    },
    steps: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      instant: 0
    }
  },
  computed: {
    sliderSteps () {
      return this.steps - 1
    },
    dateHour () {
      const dateData = new Date()
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }
      dateData.setTime(this.init + this.instant * this.$TIME_SPLIT)
      return dateData.toLocaleDateString('en-UK', options)
    }
  },
  methods: {
    changeInstant () {
      this.$emit('onChange', this.instant)
    }
  }
}
</script>

<style scoped>
.vlp-timeSlider {
  color: #f4c020;
  margin-right: 5%;
  height: 30px;
  line-height: 30px;
  width: 90%;
}
.vlp-timeSlider span {
  margin-left: 15px;
}
.vlp-slider {
  margin-left: 25px;
  margin-right: -40px;
}
</style>
