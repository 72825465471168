<template>
  <v-row>
    <v-col
      cols="3"
      style="padding-right:0px"
    >
      <v-list style="margin-top:45px">
        <v-list-item-group
          v-model="maskSelection"
          multiple
          color="indigo"
        >
          <v-list-item
            v-for="item in tableContent"
            :key="item.name"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.name" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-col>
    <v-col
      cols="8"
      style="padding-left:0px; padding-right:0px"
    >
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th
                v-for="header in headers"
                :key="header"
                scope="column"
              >
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in tableContent"
              :key="item.name"
            >
              <td
                v-for="(penetration, index) in item.values"
                :key="item.type + '-' + index"
              >
                {{ penetration }}%
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'MontecarloTable',
  props: {
    tableContent: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      maskSelection: [0]
    }
  },
  computed: {
    ...mapState({
      digitalTwinResults: (state) => state.DTResults,
      planningType: (state) => state.planningType
    }),
    headers () {
      if (this.planningType === 'EV') {
        return this.digitalTwinResults.Results.TestPercentVec()
      }
      return this.digitalTwinResults.Results.TestPowerVec()
    }
  },
  watch: {
    maskSelection () {
      const types = []

      Object.values(this.maskSelection).forEach((selection) => {
        types.push(selection)
      })

      this.clickViolation(types)
    }
  },
  methods: {
    clickViolation (type) {
      this.$emit('clickViolation', type)
    }
  }
}
</script>

<style scoped>
  .yellow {
    background-color: #f4c020;
  }
</style>
<style lang="scss">
  tbody {
     tr:hover {
        background-color: transparent !important;
     }
  }
</style>
