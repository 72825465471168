<template>
  <VMenu offset-y>
    <template #activator="{ on, attrs }">
      <VBtn
        x-small
        text
        color="#f4c020"
        v-bind="attrs"
        class="ml-3"
        v-on="on"
      >
        {{ $t('lang') }}
      </VBtn>
    </template>
    <VList>
      <VListItem
        v-for="(item, index) in languages"
        :key="index"
        class="pa-1"
        @click="switchLocale(item.locale)"
      >
        <VListItemContent class="lang-option">
          {{ item.text }}
        </VListItemContent>
      </VListItem>
    </VList>
  </VMenu>
</template>

<script>
export default {
  name: 'CMLanguagePicker',

  data () {
    return {
      languages: [
        {
          locale: 'en',
          text: 'English'
        },
        {
          locale: 'es',
          text: 'Español'
        },
        {
          locale: 'sv',
          text: 'Svenska'
        }
      ]
    }
  },
  methods: {
    switchLocale (locale) {
      this.$i18n.locale = locale
    }
  }
}
</script>

<style lang="scss" scoped>
.lang-option {
  color: #f4c020;
  font-size: .8em;
}
</style>
