<template>
  <v-dialog
    id="custom-profile-form"
    v-model="showForm"
    max-width="550px"
    overlay-opacity="0.6"
    class="bg-secondary"
    :persistent="false"
  >
    <v-card>
      <v-form
        ref="CPForm"
        v-model="valid"
        lazy-validation
      >
        <h3
          class="text-primary pt-30"
        >
          {{ $t('importer.custom_profile') }}
        </h3>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-select
                  v-model="values.ph"
                  :items="phItems"
                  :label="$t('dt.phase_connection')"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="values.ndevs"
                  :rules="VALIDATIONS.ndevs"
                  :label="$t('new_connections.num_custom_profiles')"
                  type="number"
                  dense
                  @change="setToNumber('ndevs')"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="values.sampleTime"
                  :rules="VALIDATIONS.sampleTime"
                  :label="$t('new_connections.time_sampling') +' (' + $t('seconds')+ ')' "
                  type="number"
                  :suffix="$t('second_abb')"
                  dense
                  @change="setToNumber('sampleTime')"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <DataUpload
          :custom-profile="true"
          :accepted-extensions="'.csv'"
          :show-title="false"
          :disabled-upload="payload.edit"
          :title-edit-file="values.ref"
          @change="change"
        />
        <v-card-actions>
          <v-spacer />
          <v-btn
            id="cancel"
            text
            @click="closeForm"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            v-if="payload.edit"
            id="edit"
            :disabled="!valid"
            text
            @click="editDevice"
          >
            {{ $t('edit') }}
          </v-btn>
          <v-btn
            v-if="payload.edit"
            id="delete"
            text
            @click="deleteDevice"
          >
            {{ $t('delete') }}
          </v-btn>
          <v-btn
            v-else
            id="add"
            :disabled="!enableConfirm || !valid"
            data-cy="dt-add-ev"
            text
            @click="addDevice"
          >
            {{ $t('add') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import DataUpload from '@/views/DataUpload.vue'
import digitalTwinForm from '@/mixins/digitalTwinForm'

export default {
  name: 'DTNewCustomProfileForm',
  components: {
    DataUpload
  },
  mixins: [digitalTwinForm],
  data () {
    return {
      DEVICE: 'CP',
      values: {
        ph: 4,
        ref: this.payload.edit ? this.payload.changes.ref : '',
        P: [],
        Q: [],
        sampleTime: 3600,
        ndevs: 1
      },
      enableConfirm: false
    }
  },
  methods: {
    change (value) {
      this.values.P = value.P
      this.values.Q = value.Q
      this.values.ref = value.ref
      this.enableConfirm = true
    }
  }
}
</script>
<style>
  .v-dialog .theme--dark.v-btn {
    color:#f4c020
  }

  .v-dialog .form {
    box-shadow:none;
  }

  .v-dialog {
    background: #1e1e1e;
  }

  .v-dialog .upload > .form {
    margin: 0;
    padding: 0;
  }

  .pt-30 {
    padding-top: 30px
  }

  #custom-profile-form {
    z-index: 501;
  }

</style>
