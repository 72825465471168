<template>
  <div class="map-timeslider">
    <VRow>
      <v-col
        v-if="!hostingCapacityMap"
        class="text-left pb-0 ml-10"
        cols="2"
      >
        <v-text-field
          v-if="dateInput && !hostingCapacityMap"
          v-model="dateInput"
          class="mt-0 pt-0"
          type="date"
          :max="dateMax"
          :min="dateMin"
          color="#f4c020"
          step
        />
      </v-col>
      <v-col
        class="text-left pb-0"
        cols="1
        "
      >
        <v-text-field
          v-if="hourInput"
          v-model="hourInput"
          class="mt-0 pt-0"
          type="time"
          style="width: 60px"
          step="3600"
          color="#f4c020"
          dark
          @change="changeHourInstant"
        />
      </v-col>
    </VRow>
    <VRow>
      <VCol cols="1">
        <VIcon
          color="#f4c020"
          @click="previousInstant"
        >
          mdi-minus
        </VIcon>
      </VCol>
      <VCol cols="10">
        <VueSlider
          v-if="steps"
          v-model="instant"
          drag-on-click
          :lazy="true"
          :marks="violationsInstants"
          :min="0"
          :max="sliderSteps"
          :process-style="{ backgroundColor: '#f4c020'}"
          :tooltip="'none'"
          @change="changeInstant"
        />
      </VCol>
      <VCol cols="1">
        <VIcon
          color="#f4c020"
          @click="nextInstant"
        >
          mdi-plus
        </VIcon>
      </VCol>
    </VRow>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'CMTimeSlider',
  props: {
    init: {
      type: Number,
      required: true
    },
    steps: {
      type: Number,
      required: true
    },
    timesampling: {
      type: Number,
      default: 3600
    },
    hostingCapacityMap: {
      type: Boolean,
      default: false
    },
    initInstant: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      instant: 0,
      dateInput: null,
      hourInput: null,
      violationsInstants: {},
      instantUpdatedFromVioTable: false
    }
  },
  computed: {
    ...mapState(['violations']),
    sliderSteps () {
      return this.steps - 1
    },
    dateMin () {
      const dateData = new Date()
      dateData.setTime(this.init)
      return `${dateData.getFullYear()}-${('0' + (dateData.getMonth() + 1)).slice(-2)}-${('0' + dateData.getDate()).slice(-2)}`
    },
    dateMax () {
      const dateData = new Date()
      dateData.setTime(this.init + this.sliderSteps * (this.timesampling * 1000))
      return `${dateData.getFullYear()}-${('0' + (dateData.getMonth() + 1)).slice(-2)}-${('0' + dateData.getDate()).slice(-2)}`
    },
    dateLabel () {
      const dateData = new Date()
      dateData.setTime(this.init + this.instant * (this.timesampling * 1000))
      return `${dateData.getFullYear()}-${('0' + (dateData.getMonth() + 1)).slice(-2)}-${('0' + dateData.getDate()).slice(-2)}`
    },
    hourLabel () {
      const dateData = new Date()
      dateData.setTime(this.init + this.instant * (this.timesampling * 1000))
      return `${('0' + dateData.getHours()).slice(-2)}:${('0' + dateData.getMinutes()).slice(-2)}`
    }
  },
  watch: {
    dateLabel () {
      this.dateInput = this.dateLabel
    },
    hourLabel () {
      this.hourInput = this.hourLabel
    },
    dateInput () {
      !this.instantUpdatedFromVioTable && this.updateInstant()
      !this.hostingCapacityMap && this.changeInstant()
    },
    hourInput () {
      !this.instantUpdatedFromVioTable && this.updateInstant()
      !this.hostingCapacityMap && this.changeInstant()
    },
    initInstant: {
      deep: true,
      immediate: true,
      handler () {
        if (this.initInstant !== null && this.initInstant >= 0) {
          this.instant = this.initInstant
        }
      }
    },
    violations: {
      deep: true,
      handler () {
        this.setViolationInstants(this.violations)
      }
    }
  },
  mounted () {
    this.dateInput = this.dateLabel
    this.hourInput = this.hourLabel
  },
  methods: {
    changeInstant () {
      this.$emit('changeInstant', this.instant)
    },
    nextInstant () {
      this.instant++
      this.changeInstant()
    },
    previousInstant () {
      if (this.instant - 1 <= 0) {
        this.instant = 0
        return
      }

      this.instant--
      this.changeInstant()
    },
    updateInstant () {
      const hm = this.hourInput.split(':')
      const dateData = new Date(this.dateInput)
      dateData.setHours(hm[0], hm[1])
      const time = (dateData.getTime() - this.init) / (this.timesampling * 1000)
      this.instant = time < 0 ? (time + 24) : time
    },

    changeHourInstant () {
      this.updateInstant()
      this.changeInstant()
    },

    setViolationInstants (violations = {}) {
      const { data = [], selected = [] } = violations
      const { instant: selectedInstant } = selected || {}

      this.instant = selectedInstant || 0
      this.instantUpdatedFromVioTable = true

      this.violationsInstants = data.reduce((obj, violation) => ({
        ...obj,
        [violation.instant]: selectedInstant !== violation.instant
          ? '⚠️'
          : '☢️'
      }), {})

      setTimeout(() => {
        this.instantUpdatedFromVioTable = false
      }, 2000)
    }
  }
}
</script>

<style scoped>
.map-timeslider {
  color: #f4c020;
  z-index: 500;
  position: relative;
  bottom: 180px;
  float: left;
  height: 30px;
  line-height: 30px;
  width: 85%;
  margin: auto;
}
.theme--dark.v-input input, .theme--dark.v-input textarea {
    color: #f4c020;
}
</style>
