<template>
  <v-app id="inspire">
    <Plotly
      :data="dataPlot"
      :layout="getLayoutPlot"
      :displaylogo="false"
      style="height:450px!important"
    />
  </v-app>
</template>

<script>

import { Plotly } from 'vue-plotly'
// import { mapState } from 'vuex';

export default {
  name: 'MontecarloPlot',
  components: {
    Plotly
  },
  props: {
    x: {
      type: Array,
      default: null
    },
    y: {
      type: Array,
      default: null
    },
    title: {
      type: String,
      default: null
    }
  },

  computed: {
    dataPlot () {
      const data = [{
        x: this.x,
        y: this.y,
        type: 'scatter',
        mode: 'lines'
        // name: this.title,
      }]
      return data
    },
    getLayoutPlot () {
      const layout = {
        // title: this.title,
        legend: {
          orientation: 'h',
          valign: 'top',
          yanchor: 'top',
          bgcolor: 'rgba(30,30,30,0)',
          font: {
            size: '8',
            family: 'Faktum Light'
          }
        },
        height: 450,
        margin: {
          l: 60,
          r: 20,
          b: 100,
          t: 80,
          pad: 5
        },
        xaxis: {
          title: `${this.$t('planning.level_of_penetration')} (%)`,
          linecolor: '#fff',
          nticks: 5,
          gridcolor: '#2c2c2c',
          showspikes: true,
          spikethickness: 2,
          spikedash: 'dot',
          spikecolor: '#fff',
          spikemode: 'across',
          tickangle: 0,
          tickfont: {
            size: 10
          }
        },
        yaxis: {
          title: `${this.$t('planning.violation_percentage')} (%)`,
          linecolor: '#fff',
          gridcolor: '#2c2c2c'
        },
        paper_bgcolor: '#1E1E1E',
        plot_bgcolor: '#1E1E1E',
        font: {
          color: '#fff',
          family: 'Faktum Light'
        },
        hovermode: 'x',
        modebar: {
          activecolor: '#f4c020',
          color: '#fff',
          remove: ['zoomin2d', 'zoomout2d', 'resetscale2d']
        },
        line: {
          color: '#f4c020',
          width: 3
        }
      }
      return layout
    }
  },

  methods: {

  }
}
</script>

<style scoped>

</style>
