<template>
  <div class="plotStation">
    <Plotly
      :data="getDataPlot"
      :layout="getLayoutPlot"
      :display-mode-bar="false"
      :displaylogo="false"
    />
  </div>
</template>

<script>
import { Plotly } from 'vue-plotly'
export default {
  name: 'TIAMonitoringPlotStation',

  components: {
    Plotly
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }

  },
  data: () => ({
    heightPlot: null,
    nonFlexValue: [],
    flexValue: [],
    outLimitsValue: [],
    DOEValue: [],
    powerLimit:[]
  }),

  computed: {
    getDataPlot () {
      const proxyData = new Proxy(this.data, {
        // Handler del proxyData
        get (target, prop) {
          return target[prop]
        }
      })
      // Variables for Plot
      let datos = []
      let measurement
      let timePast =[]
      let timeFuture =[]      
      let consumed_total_power =[]
      let consumed_non_flex_power=[]
      let predicted_total_power=[]  
      let predicted_non_flex=[]  
      let forecast_total_power=[]     
      let forecast_non_flex=[]   
      let timeDay = []
      let doePast = []
      let doeFuture = []

      //Limit
      timeDay=this.getDayTime(proxyData) 
      measurement = {
        x: timeDay,
        y: this.powerLimit,
        type: 'lines',
        mode: 'lines',
        name: "Power Limit",
        line: {
          color: 'rgba(255,80,90, 0.8)',
          dash: 'solid',
          width: 3
        },
        visible: true
      }        
      datos.push(measurement)

      //PAST
      if (proxyData["past"].length >0){
        // create the series fot the past
        proxyData["past"].forEach((measur) => {
          timePast.push(measur[0])          
          consumed_total_power.push(measur[1])
          consumed_non_flex_power.push(measur[2])
          predicted_total_power.push(measur[3])
          predicted_non_flex.push(measur[4])
        })
        // DOE Past
        doePast = this.calculateDOEPast(this.powerLimit, predicted_non_flex, timeDay)
        //create the measurement from series
        // ADD DOE
        measurement = {
          x: timePast,
          y: doePast,
          type: 'bar',
          mode: 'lines',
          name: 'DOE Past',
          line: {
            shape: 'hvh'
          },
          visible: true,
          marker: {
            color: 'rgba(158,202,225, 0)',
            opacity: 1,
            line: {
              color: 'rgba(158,202,225,0.4)',
              width: 0.9
            }
          }
        }
        datos.push(measurement)

        //Total Power Consumed
        measurement = {
          x: timePast,
          y: consumed_total_power,
          type: 'lines',
          mode: 'lines',
          name: "Total Power Consumed",
          line: {
            color: 'rgba(171, 235, 198 , 0.2)',
            dash: 'solid',
            width: 3
          },
          visible: true
        }        
        datos.push(measurement)

        //Non flex Power Consumed
        measurement = {
          x: timePast,
          y: consumed_non_flex_power,
          type: 'lines',
          mode: 'lines',
          name: "Non flex Power Consumed",
          line: {
            color: 'rgba(158,180,240, 0.4)',
            dash: 'solid',
            width: 3
          },
          visible: true
        }        
        datos.push(measurement)
        //Predicted Total Power
        measurement = {
          x: timePast,
          y: predicted_total_power,
          type: 'lines',
          mode: 'lines',
          name: "Predicted Total Power",
          line: {
            color: 'rgba(171, 235, 198 , 1)',
            dash: 'solid',
            width: 3
          },
          visible: 'legendonly'
        }        
        datos.push(measurement)
        //Predicted Non Flex
        measurement = {
          x: timePast,
          y: predicted_non_flex,
          type: 'lines',
          mode: 'lines',
          name: "Predicted Non Flex Power",
          line: {
            color: 'rgba(158,180,240, 1)',
            dash: 'solid',
            width: 3
          },
          visible: 'legendonly'
        }        
        datos.push(measurement)
      }
      //FUTURE
      if (proxyData["future"].length >0){
        // create the series fot the past
        proxyData["future"].forEach((measur) => {
          timeFuture.push(measur[0])
          forecast_total_power.push(measur[1])
          forecast_non_flex.push(measur[2])
        })
        //create the measurement from series
        // DOE Past
        doeFuture = this.calculateDOEFuture(this.powerLimit, forecast_non_flex, timeDay)
        //create the measurement from series
        // ADD DOE
        measurement = {
          x: timeFuture,
          y: doeFuture,
          type: 'bar',
          mode: 'lines',
          name: 'DOE Future',
          line: {
            shape: 'hvh'
          },
          visible: true,
          marker: {
            color: 'rgba(158,202,225, 0)',
            opacity: 1,
            line: {
              color: 'rgb(158,202,225)',
              width: 0.9
            }
          }
        }
        datos.push(measurement)

        //Forecast Total Power
        measurement = {
          x: timeFuture,
          y: forecast_total_power,
          type: 'lines',
          mode: 'lines',
          name: "Forecast Total Power",
          line: {
            color: 'rgba(171, 235, 198 , 1)',
            dash: 'solid',
            width: 3
          },
          visible: true
        }        
        datos.push(measurement)
        //create the measurement from series
        //Forecast Non Flex Power
        measurement = {
          x: timeFuture,
          y: forecast_non_flex,
          type: 'lines',
          mode: 'lines',
          name: "Forecast Non Flex Power",
          line: {
            color: 'rgba(158,180,240, 1)',
            dash: 'solid',
            width: 3
          },
          visible: true
        }        
        datos.push(measurement)


      }
      return datos
    },

    getLayoutPlot () {
      const layout = {
        title: '',
        legend: {
          orientation: 'h',
          entrywidth: 200,
          valign: 'top',
          yanchor: 'bottom',
          y: 1.02,
          xanchor: 'right',
          x: 1,
          bgcolor: '#272727',
          font: {
            size: '12',
            family: 'Faktum Light'
          }

        },
        barmode: 'bar',
        height: this.heightPlot,
        margin: {
          l: 60,
          r: 10,
          b: 100,
          t: 0,
          pad: 5
        },
        xaxis: {
          title: 'Time (h)',
          linecolor: '#fff',
          nticks: 10,
          gridcolor: '#595959',
          showspikes: true,
          spikethickness: 2,
          spikedash: 'dot',
          spikecolor: '#fff',
          spikemode: 'across',
          tickangle: 0,
          tickfont: {
            size: 12
          }
        },
        yaxis: {
          title: '(KW)',
          linecolor: '#fff',
          gridcolor: '#595959'
        },
        paper_bgcolor: '#272727',
        plot_bgcolor: '#272727',
        font: {
          color: '#fff',
          family: 'Faktum Light'
        },
        hovermode: 'x',
        modebar: {
          activecolor: '#f4c020',
          color: '#fff',
          remove: ['zoomin2d', 'zoomout2d', 'resetscale2d']
        }
      }

      return layout
    }

  },

  mounted () {
    this.heightPlot = window.innerHeight * 0.40
  },
  methods: {

     getDayTime(data) {
      const result = []
      const maxPower = this.$store.state.TIA.TIAMap.maxPowerStation      
      data["past"].forEach((measur) => {
        result.push(measur[0])
        this.powerLimit.push(maxPower)
      })
      data["future"].forEach((measur) => {
        result.push(measur[0])
        this.powerLimit.push(maxPower)
      })
      return result
    },
    minusArrays (array1, array2) {
      if (array1.length !== array2.length) {
        throw new Error('Arrays must have the same lenght.')
      }
      const resultado = []
      for (let i = 0; i < array1.length; i++) {
        const resta = array1[i] - array2[i]
        resultado.push(resta)
      }

      return resultado
    },
    calculateFlexAndOutLimits (nonFlex, flex, maxPwr) {
      const flexValues = []
      const DOEValues = []
      const outLimitsValues = []

      for (let i = 0; i < nonFlex.length; i++) {
        const totalPower = nonFlex[i] + flex[i]

        if (totalPower <= maxPwr[i]) {
          // If the sum of nonFlex and flex is less than or equal to maxPwr, the value is equal to the value of flex.
          flexValues.push(flex[i])
          DOEValues.push(maxPwr[i] - totalPower)
          outLimitsValues.push(0)
        } else {
          // If the sum of nonFlex and flex is greater than maxPwr, we calculate the value of flexValues and outLimitsValues
          flexValues.push(maxPwr[i] - nonFlex[i])
          DOEValues.push(0)
          outLimitsValues.push(totalPower - maxPwr[i])
        }
      }
      this.outLimitsValue = outLimitsValues
      this.DOEValue = DOEValues
      return flexValues
    },
    calculateDOEPast(powerLimit, nonFlexPower, timeDay){  
      let DOE = []   
      let minSize = Math.min(powerLimit.length, nonFlexPower.length)

      for (let i = 0; i < minSize; i++) {
        DOE.push(powerLimit[i] - nonFlexPower[i])                
      }
      return DOE
    },
    calculateDOEFuture(powerLimit, nonFlexPower, timeDay){  
      let DOE = [] 
      for (let i = 0; i < nonFlexPower.length; i++) {
          DOE.push(powerLimit[i] - nonFlexPower[i])        
      }
      return DOE
    }

  }
}
</script>

<style scoped>
.plotStation{
  margin-top: 20px!important;
}

</style>