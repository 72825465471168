<template>
  <v-app id="inspire">
    <div
      :id="name"
      class="pft-container"
    >
      <div v-if="loading">
        <v-row
          align="center"
          justify="space-around"
          class="shrink"
        >
          <button
            class="letraCoorporativa"
            text
            color="primary"
            @click="toggleText"
          >
            {{ enclosure }}
          </button>
          <v-switch
            v-model="data_relative"
            class="letraCoorporativa"
            :label="`Value Relative`"
            @change="dataRelative()"
          />
          <select
            id="typeValueChoose"
            class="letraCoorporativa"
            @change="selectClick($event)"
          >
            <option value="1">
              Minimun
            </option>
            <option value="2">
              Maximum
            </option>
            <option value="3">
              Average
            </option>
            <option value="4">
              Phase A
            </option>
            <option value="5">
              Phase B
            </option>
            <option value="6">
              Phase C
            </option>
          </select>
        </v-row>
        <div class="slidervlp">
          <TimeSlider
            :steps="simParams.StepCount"
            :init="simParams.StartTime"
            @onChange="onChange"
          />
        </div>
      </div>
      <div v-else>
        <CMLoader
          :loading="loadingSelf.isVisible"
          :message="loadingSelf.message"
        />
      </div>
    </div>
  </v-app>
</template>

<script>
import TimeSlider from '@/components/DigitalTwin/Plots/utils/DtPanelVLPTimeSlider.vue'
import { PFT } from '@/assets/Plot/VLP/VoltageLengthPlot'
import CMLoader from '@/components/Common/CMLoader.vue'
import { mapState } from 'vuex'

export default {
  name: 'DtPanelPlotVoltageLength',
  components: {
    TimeSlider,
    CMLoader
  },
  props: {
    name: {
      type: String,
      default: null
    },
    digitalTwinResults: {
      type: Object,
      default: null
    },
    idStation: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    dt: null,
    loading: false,
    value: 0,
    substation: null,
    myPFT: null,
    case: 0,
    instant: 0,
    simParams: {
      StartTime: null,
      StepCount: null,
      BasePower: null
    },

    enclosure: 'Hide Enclosure',
    phases: 'Show Phases',
    electricResults: 0,
    BBDD: 0,
    data_relative: true,
    loadingSelf: {
      isVisible: true,
      message: 'Loading Voltage Lenght'
    }
  }),

  computed: {
    ...mapState({
      currentCase: (state) => state.currentCase,
      simulatedCase: (state) => state.simulatedCase
    })
  },

  watch: {
    idStation () {
      this.loading = false
      this.substation = this.idStation
      this.myPFT = new PFT(this.name, this.case, this.instant, this.substation,
        this.dt.DB, this.dt.Results, this.simParams.StepCount,
        this.$API_HOST, this.$API_PORT, this.$HEADER_HTTP,
        document.getElementById('typeValueChoose').value, this.data_relative)
      this.loading = true
    },
    currentCase () {
      // eslint-disable-next-line
      const currentCase = this.$store.state.currentCase;
      this.cases = this.$store.state.DTResults.cases
      if (currentCase === undefined) {
        this.case = 0
      } else {
        this.case = this.cases[currentCase].idx
      }
      this.loading = true

      this.myPFT = new PFT(this.name, this.case, this.instant, this.substation,
        this.BBDD, this.electricResults, this.simParams.StepCount,
        this.$API_HOST, this.$API_PORT, this.$HEADER_HTTP,
        document.getElementById('typeValueChoose').value, this.data_relative)
    },
    simulatedCase () {
      // eslint-disable-next-line
      const currentCase = this.$store.state.currentCase;
      this.cases = this.$store.state.DTResults.cases
      if (currentCase === undefined) {
        this.case = 0
      } else {
        this.case = this.cases[currentCase].idx
      }

      this.loading = true
      this.myPFT = new PFT(this.name, this.case, this.instant, this.substation,
        this.BBDD, this.electricResults, this.simParams.StepCount,
        this.$API_HOST, this.$API_PORT, this.$HEADER_HTTP,
        document.getElementById('typeValueChoose').value, this.data_relative)
    }
  },

  created () {
    // Case out
    this.simParams.StepCount = this.$store.state.DTProject.sim.StepCount - 1
    this.simParams.StartTime = this.$store.state.DTProject.sim.StartTime
    // eslint-disable-next-line
    const currentCase = this.$store.state.currentCase;
    this.cases = this.$store.state.DTResults.cases
    if (currentCase === undefined) {
      this.case = 0
    } else {
      this.case = this.cases[currentCase].idx
    }
  },

  async mounted () {
    // CASE CONFIGURATION
    this.dt = this.digitalTwinResults
    this.BBDD = this.dt.DB
    this.electricResults = this.dt.Results
    this.substation = this.idStation

    this.myPFT = new PFT(this.name, this.case, this.instant, this.substation,
      this.dt.DB, this.dt.Results, this.simParams.StepCount,
      this.$API_HOST, this.$API_PORT, this.$HEADER_HTTP,
      1, this.data_relative)

    this.loading = true
  },

  methods: {
    onChange (instantSelected) {
      this.instant = instantSelected
      this.myPFT.updateGraph(this.name, this.case, this.instant, document.getElementById('typeValueChoose').value, this.data_relative)
    },
    toggleText () {
      const x = document.getElementById('enclosure')
      if (x.style.display === 'none') {
        x.style.display = 'block'
        this.enclosure = 'Hide Enclosure'
      } else {
        x.style.display = 'none'
        this.enclosure = 'Show Enclosure'
      }
    },
    dataRelative () {
      this.loading = false
      this.myPFT = new PFT(this.name, this.case, this.instant, this.substation,
        this.BBDD, this.electricResults, this.simParams.StepCount,
        this.$API_HOST, this.$API_PORT, this.$HEADER_HTTP,
        document.getElementById('typeValueChoose').value, this.data_relative)
      this.loading = true
    },
    selectClick (e) {
      this.loading = false
      this.myPFT = new PFT(this.name, this.case, this.instant, this.substation,
        this.BBDD, this.electricResults, this.simParams.StepCount,
        this.$API_HOST, this.$API_PORT, this.$HEADER_HTTP,
        e.target.value, this.data_relative)
      this.loading = true
    }
  }
}
</script>

<style>
:root{
    --pft-bg-color: rgb(25,25,25);
    --pft-line-width: 1.5;
    --pft-opacity: 0.3;
}
.slidervlp{
  margin-top: -40px;
  margin-left: 70px;
  margin-bottom: 20px;
}
.letraCoorporativa{
  font-size: 14px;
  font-family: 'Faktum Regular', sans-serif;
  color:white;
  background-color:#191919;
}
.pft-container{
    background-color: var(--pft-bg-color);
    font-size: 14px;
    font-family: 'Faktum Regular', sans-serif;

}

.yaxis .tick line, #grid .domain, #grid .tick line{
    stroke: rgb(253, 253, 253);
    opacity: var(--pft-opacity);
}

#legend{
  cursor: pointer;
  font-family: 'Faktum Regular', sans-serif;

}
</style>
