<template>
  <VNavigationDrawer
    style="z-index:505"
    permanent
    clipped
    mini-variant
    color="#f4c020"
    app
  >
    <VList
      v-if="listVisible"
      nav
    >
      <VListItem
        v-for="page in pages"
        :key="page.routerName"
        link
      >
        <VListItemIcon :id="page.id">
          <RouterLink

            :to="{name: page.routerName}"
          >
            <VTooltip right>
              <template #activator="{ on }">
                <VImg

                  :src="require(`@/assets/images/${page.image}`)"
                  width="25"
                  v-on="on"
                />
              </template>
              <span>{{ $t(page.title) }}</span>
            </VTooltip>
          </RouterLink>
        </VListItemIcon>
      </VListItem>
    </VList>
  </VNavigationDrawer>
</template>

<script>
import { getInfo } from '@/services/user'
export default {
  data: () => ({
    name: 'LYSideBar',
    pages: [],
    info: null
  }),

  computed: {
    logged () {
      return this.$sessionStorage.logged
    },

    zoneId () {
      const { zoneId } = this.$sessionStorage
      return ![undefined, null].includes(zoneId)
    },

    gridElement () {
      const { gridElement } = this.$sessionStorage
      return ![null, 'null'].includes(gridElement)
    },

    listVisible () {
      const isMeasurements = this.$route.name === 'Measurements'
      return this.logged && (this.zoneId || this.gridElement || isMeasurements)
    }
  },
  async mounted () {
    this.info = await getInfo()

    this.pages = [
      {
        title: 'grid_inspector_title',
        image: 'grid.png',
        routerName: 'GridInspection'
      },
      {
        title: 'digital_twin',
        image: 'rayo.png',
        routerName: 'DigitalTwinSet'
      },
      {
        title: 'grid_monitor_title',
        image: 'enchufe.png',
        routerName: 'Measurements'
      }
    ]
    if (this.info.has_tia) {
      this.pages.push({
        id: 'icon-tia',
        title: 'tia_map_title',
        image: 'tia.png',
        routerName: 'TIA'
      })
    }
  }
}
</script>

<style>
.v-navigation-drawer--clipped:not(.v-navigation-drawer--temporary)
:not(.v-navigation-drawer--is-mobile) {
    z-index: 502;
}

nav.v-navigation-drawer--clipped:not(.v-navigation-drawer--temporary)
:not(.v-navigation-drawer--is-mobile) {
    z-index: 502;
}

.tooltip{
  background-color:black;
  color:white;
}
</style>
