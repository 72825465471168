<template>
  <v-dialog
    v-model="showForm"
    style="z-index:501"
    max-width="450"
    overlay-opacity="0.6"
  >
    <v-card elevation="10">
      <v-form
        ref="PVForm"
        v-model="valid"
        lazy-validation
      >
        <v-card-title class="text-h5">
          {{ $t('new_connections.pv_conn_params') }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="values.ndevs"
                  :rules="VALIDATIONS.ndevs"
                  data-cy="dt-pv-ndevs"
                  dense
                  :label="$t('new_connections.n_pvs')"
                  type="number"
                  @change="setToNumber('ndevs')"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="values.ph"
                  :items="phItems"
                  :label="$t('new_connections.phase_connection')"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="values.pp"
                  :rules="VALIDATIONS.pp"
                  label="Installed peak power"
                  type="number"
                  suffix="kW"
                  @change="setToNumber('pp')"
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="values.mode"
                  :items="modeItems"
                  :label="$t('mode')"
                />
              </v-col>
            </v-row>
            <v-row v-if="values.mode === 0">
              <v-col cols="12">
                <v-text-field
                  v-model="values.q_max"
                  :rules="VALIDATIONS.q_max"
                  dense
                  :label="$t('new_connections.max_q_injection')"
                  type="number"
                  @change="setToNumber('q_max')"
                />
              </v-col>
            </v-row>
            <v-row v-if="values.mode === 1">
              <v-col cols="12">
                <v-text-field
                  v-model="values.pf"
                  :rules="VALIDATIONS.pfpv"
                  dense
                  label="Power Factor"
                  type="number"
                  step="0.1"
                  @change="setToNumber('pf')"
                />
              </v-col>
            </v-row>
            <v-row v-if="values.mode === 2">
              <v-col cols="6">
                <v-text-field
                  v-model="values.dv1"
                  :rules="VALIDATIONS.dv"
                  dense
                  label="dV1"
                  type="number"
                  step="0.01"
                  @change="setToNumber('dv1')"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="values.dv2"
                  :rules="VALIDATIONS.dv"
                  dense
                  label="dV2"
                  type="number"
                  step="0.01"
                  @change="setToNumber('dv2')"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="values.vreg"
                  :rules="VALIDATIONS.vreg"
                  dense
                  label="Vreg"
                  type="number"
                  step="0.01"
                  @change="setToNumber('vreg')"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="values.dQ"
                  :rules="VALIDATIONS.dQ"
                  dense
                  label="dQ"
                  type="number"
                  step="0.01"
                  @change="setToNumber('dQ')"
                />
              </v-col>
              <v-col cols="12">
                <v-img
                  max-width="450"
                  src="@/assets/images/PVvoltageVAR.png"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#f4c020"
            text
            @click="closeForm"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            v-if="payload.edit"
            :disabled="!valid"
            data-cy="dt-pv-edit-element"
            color="#f4c020"
            text
            @click="editDevice"
          >
            {{ $t('edit') }}
          </v-btn>
          <v-btn
            v-if="payload.edit"
            data-cy="dt-pv-delete-element"
            color="#f4c020"
            text
            @click="deleteDevice"
          >
            {{ $t('delete') }}
          </v-btn>
          <v-btn
            v-else
            :disabled="!valid"
            data-cy="dt-add-pv"
            color="#f4c020"
            text
            @click="addDevice"
          >
            {{ $t('add') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import digitalTwinForm from '@/mixins/digitalTwinForm'

export default {
  name: 'NewConnectionsPVForm',

  mixins: [digitalTwinForm],

  data () {
    return {
      DEVICE: 'PV',
      values: {
        ndevs: 1,
        ph: 4,
        pp: 5,
        mode: 0,
        q_max: 1,
        pf: 1,
        vreg: 1,
        dv1: 0.1,
        dv2: 0.1,
        dQ: 0.01
      }
    }
  }
}
</script>
