<template>
  <div v-if="loadingSelf.isVisible">
    <CMLoader
      :loading="loadingSelf.isVisible"
      :message="loadingSelf.message"
    />
  </div>
  <div v-else-if="loadingSelf.isVisible == false">
    <div>
      <TIANavBar />
    </div>
    <div>
      <TIAMonitoringLayout
        :stations="stations"
        :monitor-station="monitorStation"
        :monitor-station-plot="monitorStationPlot"
      />
      <TIAMapInformation
        :class-assigned="classAssigned"
      />
    </div>
  </div>
</template>

<script>

import CMLoader from '@/components/Common/CMLoader.vue'
import { getFlexContainers, getMonitorStation, getMonitorTransformerPlot } from '@/services/TIA'
import TIAMonitoringLayout from '@/components/TIA/TIAMonitoring/TIAMonitoringLayout.vue'
import TIANavBar from '@/components/TIA/TIANavBar.vue'
import TIAMapInformation from '@/components/TIA/TIAMap/TIAMapInformation.vue'
import { mapState } from 'vuex'
import getLocalDateFormated from '@/mixins/TIA/getLocalDateFormated'
import checkTimeMachine from '@/mixins/TIA/checkTimeMachine'
export default {
  name: 'TIAMonitoring',
  components: {
    CMLoader,
    TIAMonitoringLayout,
    TIAMapInformation,
    TIANavBar
  },
  mixins: [getLocalDateFormated, checkTimeMachine],
  data: () => ({
    stations: [],
    monitorStation: [],
    monitorStationPlot: [],
    error: false,
    loadingSelf: {
      isVisible: null,
      message: 'Loading TIA Monitoring'
    },
    classAssigned: 'monitoring-info',
    dateMachine: null
  }),
  computed: {
    ...mapState({
      stationSelected: (state) => state.TIA.TIAMap.stationSelected,
      periodSelected: (state) => state.TIA.TIAMonitoring.periodSelected,
      changePower: (state) => state.TIA.TIAMonitoring.changePower,
      timeMachine: (state) => state.TIA.timeMachine
    })
  },

  watch: {
    stationSelected () {
      this.showLoading(true)
      this.getDataStation()
      this.getDataMonitorTransformerPlot()
      setTimeout(() => { this.showLoading(false) }, 500)
    },
    periodSelected () {
      this.getDataMonitorTransformerPlot()
    },
    changePower () {
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.changePower', value: false })
      this.showLoading(true)
      this.getDataStation()
      this.getDataMonitorTransformerPlot()
      setTimeout(() => { this.showLoading(false) }, 500)
    },
    timeMachine () {
      this.showLoading(true)
      this.getDataStation()
      this.getDataMonitorTransformerPlot()
      setTimeout(() => { this.showLoading(false) }, 500)
    }

  },
  mounted () {
    this.showLoading(true)
    this.getData()
    this.getDataMonitorTransformerPlot()
    setTimeout(() => { this.showLoading(false) }, 1500)
  },

  methods: {
    async getData () {
      try {
        this.stations = await getFlexContainers()
        const id = this.$store.state.TIA.TIAMap.stationSelected
        this.monitorStation = await getMonitorStation(id)
        let uuidCustomer
        if (this.monitorStation[3].lenght > 0) {
          uuidCustomer = this.monitorStation[3].individual_customer_info[0].uuid
        } else {
          uuidCustomer = '-'
        }
        this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.customerSelected', value: uuidCustomer })
      } catch (error) {
        console.log(error)
        this.error = true
      }
    },
    async getDataStation () {
      try {
        const id = this.$store.state.TIA.TIAMap.stationSelected
        this.monitorStation = await getMonitorStation(id)
        const uuidCustomer = this.monitorStation[3].individual_customer_info[0].uuid
        this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.customerSelected', value: uuidCustomer })
      } catch (error) {
        console.log(error)
        this.error = true
      }
    },
    async getDataMonitorTransformerPlot () {
      try {
        const id = this.$store.state.TIA.TIAMap.stationSelected
        const period = this.$store.state.TIA.TIAMonitoring.periodSelected

        let dateStart
        let dateEnd
        let timeMiddle
        if (period === 'today') {
          dateStart = this.getLocalDateFormated(0, window.sessionStorage.getItem('dateMachine'))
          dateEnd = this.getLocalDateFormated(0, window.sessionStorage.getItem('dateMachine'))
          dateStart = this.checkTimeMachine(window.sessionStorage.getItem('dateMachine'), dateStart)
          dateEnd = this.checkTimeMachine(window.sessionStorage.getItem('dateMachine'), dateEnd)
          
          const timeMachine = window.sessionStorage.getItem('timeMachine')
          if(timeMachine!== null && timeMachine!== undefined){
            timeMiddle = `${dateStart}T${timeMachine}Z`
          }else{
            const fechaHoraActual = new Date()
            timeMiddle= fechaHoraActual.toISOString().substr(0,19)+'Z'
          }
        } else if (period === 'tomorrow') {
          dateStart = this.getLocalDateFormated(-1, window.sessionStorage.getItem('dateMachine'))
          timeMiddle = `${dateStart}T00:00:00Z`
          dateEnd = this.getLocalDateFormated(-1, window.sessionStorage.getItem('dateMachine'))
        } else if (period === 'yesterday') {
          dateStart = this.getLocalDateFormated(1, window.sessionStorage.getItem('dateMachine'))
          timeMiddle = `${dateStart}T23:59:00Z`
          dateEnd = this.getLocalDateFormated(1, window.sessionStorage.getItem('dateMachine'))
        }

        const timeStart = `${dateStart}T00:00:00Z`
        const timeEnd = `${dateEnd}T23:59:00Z`

        
        this.monitorStationPlot = await getMonitorTransformerPlot(id, timeStart, timeEnd, timeMiddle)        
      } catch (error) {
        console.log(error)
        this.error = true
      }
    },
    showLoading (show) {
      this.loadingSelf.isVisible = show
    }
  }
}
</script>

<style >
.splitpanes--vertical > .splitpanes__splitter {
  background-color: #333;
  position: relative;
  width: 2px;
  border-right: 2px solid rgba(238, 238, 238, 0.2);
}
.splitpanes--horizontal > .splitpanes__splitter {
  background-color: #333;
  position: relative;
  height:2px;
  border-top:2px solid rgba(238, 238, 238, 0.2);
  margin-top:-1px;
  }
</style>
