// import PowerFlowResult from './PFR_HC'
import PowerFlowResult from '@/assets/commons/PFR.js'
import { getBufferResults, getDBResults } from '../../services/hostingCapacity'
import createSqlWasm from 'sql-wasm/dist/esm/sql-wasm-browser'
import HostingCapacityMap from './HostingCapacity'

class HostingCapacityCreator {
  constructor (protocol, ip, port, infoFunc, errorFunc, warnFunc, debug) {
    this.infoFunc = infoFunc
    this.errorFunc = errorFunc
    this.warnFunc = warnFunc
    // Set-up of the sqlite interpreter. In wasmUrl it should be defined the endpoint of the
    // 'sqlite3.wasm'. IMPORTANT: this should be served by the front-end server in order to
    // avoid CORS conflicts.
    this.sqlPromise = debug ? createSqlWasm({ wasmUrl: `${protocol}://${ip}:${port}/digital-twin/sqlite3-wasm/` }) : createSqlWasm({ wasmUrl: '/static/sqlite3.wasm' })
    this.host = `${ip}:${port}`
    this.protocol = protocol
    this.ws_protocol = (protocol === 'https') ? 'wss' : 'ws'
  }

  async openHostingCapacityMap (station) {
    return new Promise((resolve) => {
      const HC = this.downloadHC(station)
      return resolve(HC)
    })
  }

  async downloadHC (station) {
    /*
        Callback function when a hosting capacity was resolved.
        The database and frame buffer are downloaded. These values are used to initialize
        a Database type object (this.DB) and a PowerFlowResult object(this.Network).
        Params:
            * url_db: endpoint to download the sqlite database.
            * url_res: endpoint to download the frame buffer with the simulation results.
        */
    const db = getDBResults(`${this.protocol}://${this.host}`, station) // hosting capacity service to get database
    const buffer = getBufferResults(`${this.protocol}://${this.host}`, station) // hosting capacity service to get buffer results
    return Promise.all([this.sqlPromise, db, buffer])
      .then((responses) => {
        const SQLObject = responses[0]
        // Load received data to SQL object
        this.DB = new SQLObject.Database(responses[1])
        // Constants initialization for NET
        const counts = this.DB.exec('SELECT COUNT(ID) AS n, \'buses\' AS Description FROM Bus UNION ALL SELECT COUNT(ID) AS n, \'lines\' AS Description FROM Line;')[0].values
        const [tCount] = this.DB.exec('SELECT StepCount FROM CasePar;')[0].values[0]

        this.network = new PowerFlowResult()
        this.network.MemNew(counts[0][0], counts[1][0], tCount, 7)
        // Setup Buses and Lines data
        this.network.NewCase(this.DB, tCount, 0)
        // Add Voltage results to Buffer
        this.network.BufferCount += 1;
        [, , this.network.BufferList[0]] = responses
        this.network.HC_LoadBuffer(responses[2])
        return new HostingCapacityMap(this.DB, this.network, station, this.host,
          this.sqlPromise, this.warnFunc, this.errorFunc, this.warnFunc)
      })
      .catch((err) => this.errorFunc(err))
  }
}
export default HostingCapacityCreator
