<template>
  <v-app>
    <div class="full-height">
      <div v-if="showForm">
        <ASDialog
          :source="source"
          @getArea="getArea"
          @getElement="getElement"
        />
      </div>
      <div
        v-else
        class="full-height"
      >
        <div v-if="showError">
          <CMError
            :text="textError"
            :code="numberError"
            @hideError="hideError"
          />
        </div>
        <div v-else>
          <splitpanes
            class="full-height default-theme"
          >
            <pane v-if="loadingPage">
              <ProjectForm
                :key-form="keyForm"
                :show-form="dialogProject"
                :network-list="listElements.networks"
                @selectStationsOnMap="selectStationsOnMap"
                @createNewProject="createNewConnections"
                @createNewProjectPlanning="createMontecarlo"
                @openProject="openProject"
                @deleteProject="showDeleteConfirmation"
                @hideFeedback="hideFeedback"
              >
                <template
                  v-if="feedback.isVisible"
                  #feedback
                >
                  <v-alert
                    v-if="feedback.type"
                    type="warning"
                    text="feedback.message"
                  />
                </template>
              </ProjectForm>
              <v-dialog
                v-model="showConfirmation"
                max-width="400"
                overlay-opacity="0.6"
              >
                <ConfirmDelete
                  :text="$t('dt.delete_confirm_project')"
                  :items="[projectToDelete]"
                  @deleteCancel="deleteCancel"
                  @deleteConfirmation="deleteConfirmation"
                />
              </v-dialog>
              <MapBase
                v-if="mapDataReady"
                :mode="mode"
                :loading="loading"
                :loading-text="loadingMessage"
                :multiselect="true"
                :stations="stations"
                :networks="networks"
                :open-fuses="openFuses"
                :isolated-lines="isolatedLines"
                :station-open-fuses="stationOpenFuses"
                :list-elements="listElements"
                :grid-bounds="gridBounds"
                :is-select-area-enable="isSelectAreaEnable"
                @click="click"
                @changeMode="changeMode"
              />
              <div
                v-if="showButton"
                class="button-form"
              >
                <v-btn
                  class="buttonForm"
                  elevation="2"
                  @click="backToForm()"
                >
                  Back to form
                </v-btn>
              </div>
            </pane>
            <pane v-else>
              <CMLoader
                :loading="loadingSelf.isVisible"
                :message="$t('dt.loading_dt')"
              />
            </pane>
          </splitpanes>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { Splitpanes, Pane } from 'splitpanes'
import { mapState } from 'vuex'

import 'leaflet/dist/leaflet.css'
import 'splitpanes/dist/splitpanes.css'

import MapBase from '@/components/Map/MapBase.vue'
import CMLoader from '@/components/Common/CMLoader.vue'
import ProjectForm from '@/components/DigitalTwin/Forms/DTProjectForm.vue'
import ConfirmDelete from '@/components/Common/CMDeleteConfirmation.vue'
import DigitalTwinCreator from '@/assets/DigitalTwin/DigitalTwinCreator'
import unclickElements from '@/mixins/map/mapElementUnselectMixin'
import getAreaGridMixin from '@/mixins/getAreaGridMixin'
import { getAreaMap } from '@/services/areaSelector'
import CMError from '@/components/Common/CMError.vue'
import { mdiAlertCircle } from '@mdi/js'
import ASDialog from '@/components/AreaSelector/ASDialog.vue'
import { getNetworksObject } from '@/mixins/map/index'

export default {
  name: 'DigitalTwinNewConnections',

  metaInfo: {
    title: 'Digital Twin'
  },

  components: {
    MapBase,
    Splitpanes,
    Pane,
    ProjectForm,
    ConfirmDelete,
    CMLoader,
    CMError,
    ASDialog
  },
  mixins: [unclickElements, getAreaGridMixin, getNetworksObject],

  data () {
    return {
      feedback: {},
      loading: false,
      loadingPage: false,
      mode: 'network_mode',
      panelShow: false,
      showConfirmation: false,
      projectToDelete: undefined,
      idElement: null,
      typeElement: null,
      station: null,
      dialogProject: false,
      digitalTwinReceived: false,
      showButton: false,
      listElements: null,
      stations: null,
      networks: null,
      networksSelected: null,
      openFuses: null,
      isolatedLines: null,
      stationOpenFuses: [],
      keyForm: 0,
      digitalTwinResults: {},
      host: this.$API_HOST,
      port: this.$API_PORT,
      gridBounds: undefined,
      loadingSelf: {
        isVisible: true,
        message: 'Loading Digital Twin'
      },
      changeBound: false,
      isSelectAreaEnable: false,
      source: null,
      showError: false,
      numberError: null,
      textError: null,
      errorIcon: mdiAlertCircle,
      openingProject: false
    }
  },

  computed: {
    ...mapState({
      selectedNetworks: (state) => state.projectNetworks,
      showForm: (state) => state.showSetAreaForm,
      progress: (state) => state.DTProgress
    }),
    mapDataReady () {
      return (this.gridBounds !== undefined && this.stations !== undefined) 
    },
    loadingMessage(){
      let message = ''
      if (this.progress > 100 || this.openingProject ) {
        message = this.$t('dt.opening_dt')
      } else {
        message = `${this.$t('dt.generating_dt')} ${this.progress}%`
      }
      return message
    }
  },

  watch: {
    selectedNetworks () {
      this.highlightStations()
    }
  },

  async mounted () {
    this.$store.dispatch('setElement', {
      path: 'showSetAreaForm',
      value: false
    })
    if (this.$sessionStorage.zoneId !== null && this.$sessionStorage.zoneId !== 'null') {
      this.getArea()
    } else if (this.$sessionStorage.gridElement !== null) {
      this.getElement()
    } else {
      this.$store.dispatch('setElement', {
        path: 'showSetAreaForm',
        value: true
      })
    }
    this.source = await getAreaMap()
  },

  methods: {
    click (level, network) {
      const objeto = this.listElements.networks.find((element) => element.NAME === network)
      const networksOnForm = this.selectedNetworks
      let alreadySelected = -1
      if (this.selectedNetworks.length > 0) {
        alreadySelected = networksOnForm
          .findIndex((element) => element.NAME === objeto.NAME)
      }
      alreadySelected === -1
        ? networksOnForm.push(objeto)
        : networksOnForm.splice(alreadySelected, 1)
      this.$store.dispatch('setElement', { path: 'projectNetworks', value: networksOnForm })
    },
    showMap () {
      this.panelShow = false
      this.$store.dispatch('setElement', { path: 'showSetAreaForm', value: false })
    },
    setBounds () {
      this.gridBounds = {
        Xmax: parseFloat(sessionStorage.Xmax),
        Xmin: parseFloat(sessionStorage.Xmin),
        Ymax: parseFloat(sessionStorage.Ymax),
        Ymin: parseFloat(sessionStorage.Ymin)
      }
    },
    changeMode (modeSelected) {
      this.mode = modeSelected
    },

    showDialog () {
      this.dialogProject = true
    },

    selectStationsOnMap () {
      this.dialogProject = false
      this.showButton = true
      this.isSelectAreaEnable = true
    },

    backToForm () {
      this.showButton = false
      this.dialogProject = true
      this.isSelectAreaEnable = false
    },

    hideFeedback () {
      this.feedback.isVisible = false
    },

    highlightStations () {
      let selecteds = document.getElementsByClassName('polyline-selected')
      while (selecteds.length > 0) {
        this.unclickElements('polyline-selected')
      }
      selecteds = document.getElementsByClassName('circle-selected')
      while (selecteds.length > 0) {
        this.unclickElements('circle-selected')
      }
      this.selectedNetworks.forEach((stationSelected) => {
        const stationElement = document.getElementsByClassName(`network${stationSelected.NAME}`)
        stationElement.forEach((element) => {
          // replace add for toggle
          if (element.getAttribute('class').includes('circle')) {
            element.classList.add('circle-selected')
          } else if (element.getAttribute('class').includes('polyline')) {
            element.classList.add('polyline-selected')
          } else {
            console.warn('elements may include a class indicating a proper svg shape')
          }
        })
      })
    },

    getSQLGrid () {
      const sqlResponse = this.digitalTwinResults.getGrid()
      return this.getNetworksObject(sqlResponse).network.filter(n => n)
    },

    initLoading() {
      this.$store.dispatch('setElement', { path: 'DTProgress', value: 0 })
      this.openingProject = false
      this.dialogProject = false
      this.loading = true
    },

    async openProject (newConnections, projectName, details) {
      this.networksSelected = details.networks
      this.initLoading()
      this.openingProject = true
      const dtc = new DigitalTwinCreator(this.$HEADER_HTTP, this.$API_HOST, this.$API_PORT,
        () => {}, this.handleError, console.warn, this.$DEBUG)
      if (newConnections) {
        this.digitalTwinResults = await dtc.openProject(projectName)
        this.$store.dispatch('setElement', { path: 'DTResults', value: this.digitalTwinResults })
        const qualityParams = this.digitalTwinResults.getQuality()
        const simParams = this.digitalTwinResults.getParams()
        const projectParams = {
          name: projectName,
          networks: details.networks,
          networksInfo: this.getSQLGrid(),
          sim: simParams,
          quality: qualityParams,
          openFuses: this.openFuses
        }

        this.$store.dispatch('setElement', { path: 'DTProject', value: projectParams })
        this.$store.dispatch('setElement', { path: 'currentCase', value: 'base' })
        this.$router.push({ name: 'DigitalTwinNewConnections' })
      } else {
        this.digitalTwinResults = await dtc.openMontecarlo(projectName)
        this.$store.dispatch('setElement', { path: 'DTProject.name', value: projectName })
        this.$store.dispatch('setElement', { path: 'DTResults', value: this.digitalTwinResults })
        this.$router.push({ name: 'DigitalTwinPlanning' })
      }
    },

    initDigitalTwinCreator () {
      const idtc = new DigitalTwinCreator(
        this.$HEADER_HTTP,
        this.$API_HOST,
        this.$API_PORT,
        () => {}, this.handleError, console.warn,
        this.$DEBUG
      )
      return idtc
    },
    handleError (error) {
      console.log(error)
      this.loading = false
      this.numberError = 500
      this.textError = 'Digital Twin'
      this.showError = true
    },

    hideError () {
      location.reload()
    },
    toggleLoading () {
      this.loading = !this.loading
      this.dialogProject = !this.dialogProject
    },

    async createNewConnections (name, networks, simParams, qualityParams) {
      this.initLoading()
      this.networksSelected = networks

      const dtc = this.initDigitalTwinCreator()
      this.digitalTwinResults = await dtc.newProject(name, networks, simParams, qualityParams)

      if (this.digitalTwinResults.ok === false) {
        const { status, type, description } = this.digitalTwinResults
        const message = `(${status} ${type}) ${description}`

        this.feedback = { ...this.digitalTwinResults, message, isVisible: true }
        this.toggleLoading()
      } else {
        const sqlNetworksInfo = this.getSQLGrid()
        const projectParams = {
          name,
          networks,
          networksInfo: sqlNetworksInfo,
          sim: simParams,
          quality: qualityParams,
          openFuses: this.openFuses
        }

        const setters = [
          ['DTResults', this.digitalTwinResults],
          ['DTProject', projectParams],
          ['currentCase', 'base']
        ]

        setters.forEach(([path, value]) => this.$store.dispatch('setElement', { path, value }))
        this.$router.push({ name: 'DigitalTwinNewConnections' })
      }
    },

    async createMontecarlo (name, networks, simParams, qualityParams, config) {
      this.initLoading()
      const networksInfo = networks
      this.networksSelected = networks
      const dtc = this.initDigitalTwinCreator()

      await dtc.createNewMontecarlo(name, networks, simParams, qualityParams, config)

      this.digitalTwinResults = await dtc.openMontecarlo(name)
      if (this.digitalTwinResults.ok === false) {
        const { status, type, description } = this.digitalTwinResults
        const message = `(${status} ${type}) ${description}`

        this.feedback = { ...this.digitalTwinResults, message, isVisible: true }
        this.toggleLoading()
      } else {
        const projectParams = {
          name,
          networks,
          networksInfo,
          sim: simParams,
          quality: qualityParams,
          config
        }

        const setters = [
          ['DTResults', this.digitalTwinResults],
          ['DTProject', projectParams],
          ['currentCase', 'base']
        ]

        setters.forEach(([path, value]) => this.$store.dispatch('setElement', { path, value }))
        this.$router.push({ name: 'DigitalTwinPlanning' })
      }
    },

    showDeleteConfirmation (projectName) {
      this.projectToDelete = projectName
      this.showConfirmation = true
      this.dialogProject = false
    },

    deleteConfirmation () {
      this.showConfirmation = false
      this.dialogProject = true
      this.deleteProject()
    },

    deleteCancel () {
      this.projectToDelete = undefined
      this.showConfirmation = false
      this.dialogProject = true
    },

    async deleteProject () {
      const dtc = this.initDigitalTwinCreator()
      await dtc.deleteProject(this.projectToDelete)
      this.keyForm += 1
    },
    getArea () {
      this.getAreaGrid(this.drawMap)
    },
    getElement () {
      this.getElementGrid(this.drawMap)
    },
    drawMap (response) {
      this.setBounds()
      this.loadingPage = true
      const networkObject = this.getNetworksObject(response)
      this.networks = networkObject.network
      this.listElements = networkObject.list
      this.$emit('networkReady')
      this.showDialog()
      this.showMap()
    }
  }
}
</script>

<style scoped>
.button-form {
  z-index: 500;
  position: absolute;
  top: 13px;
  left: 300px;
  float: left;
}
.splitpanes__pane {
  background-color: #272727;
}
.splitpanes {
  background-color: #272727;
}

.map-contexmenu {
  z-index: 500;
}
.v-overlay {
  z-index: 500 !important;
}
.yellowPlexi {
  color: #f4c020;
}
.mtop20 {
  margin-top: 20px;
}
</style>
