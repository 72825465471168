<template>
  <div>
    <div v-if="loadingSelf.isVisible">
      <CMLoader
        :loading="loadingSelf.isVisible"
        :message="loadingSelf.message"
      />
    </div>
    <LMap
      v-else-if="loadingSelf.isVisible == false"
      id="map"
      ref="myMap"
      :center="center"
      :bounds="bounds"
      :zoom="zoom"
      :max-zoom="maxZoom"
      :padding="padding"
      :options="mapOptions"
      @ready="onMapReady"
      @click="clickMap"
    >
      <TIANavBarMap
        :list-elements="stations"
        :networks="networks"
        @toggleLoading="toggleLoading"
        @selectElement="selectElement"
        @toggleNavbar="toggleNavbar"
      />
      <LControlLayers
        v-if="showControlLayers"
        position="bottomright"
      />
      <l-control-zoom position="bottomleft" />
      <LTileLayer
        v-for="optionLayer in optionsLayer"
        :key="optionLayer.name"
        :name="optionLayer.name"
        :visible="optionLayer.visible"
        :options="layerOptions"
        :opacity="optionLayer.opacity"
        :tile-layer-class="tileLayerClass"
        :url="optionLayer.url"
        :attribution="optionLayer.attribution"
        layer-type="base"
      />
      <l-layer-group
        ref="layer"
        layer-type="overlay"
        name="Stations"
      >
        <TIAStation
          v-if="stations.length > 0"
          :zoom="zoom"
          :stations="stations"
          @openContextMenu="openContextMenu"
        />
      </l-layer-group>
      <TIAContextMenu
        :position="position"
        :station-marker="stationMarker"
      />
      <l-layer-group
        v-for="layer in networks"
        :key="layer.level"
        ref="layer"
        layer-type="overlay"
        :name="layer.level"
      >
        <MapGrid
          :layer="layer"
        />
      </l-layer-group>
    </LMap>

    <TIAMapInformation
      :coordinates-text="coordinatesLat + ', ' + coordinatesLng"
    />
  </div>
</template>

<script>
import { LMap, LTileLayer, LControlLayers, LLayerGroup, LControlZoom } from 'vue2-leaflet'
import { mapState } from 'vuex'
import L from 'leaflet'
import proj4 from 'proj4'
import 'mapbox-gl'
import 'mapbox-gl-leaflet'
import 'leaflet/dist/leaflet.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import TIAStation from '@/components/TIA/TIAMap/TIAStation.vue'
import TIAMapInformation from '@/components/TIA/TIAMap/TIAMapInformation.vue'
import TIANavBarMap from '@/components/TIA/TIAMap/TIANavBarMap.vue'
import MapGrid from '@/components/Map/MapGrid.vue'
import CMLoader from '@/components/Common/CMLoader.vue'
import TIAContextMenu from '@/components/TIA/TIAMap/TIAContextMenu.vue'

export default {
  name: 'TiaMapBase',
  components: {
    LMap,
    LTileLayer,
    LControlLayers,
    LLayerGroup,
    LControlZoom,
    TIAStation,
    TIAMapInformation,
    TIANavBarMap,
    TIAContextMenu,
    MapGrid,
    CMLoader
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    loadingText: {
      type: String,
      default: 'Fetching data'
    },
    showControlLayers: {
      type: Boolean,
      default: true
    },
    stations: {
      type: Array,
      default: () => []
    },
    networks: {
      type: Array,
      default: () => []
    },
    gridBounds: {
      type: Object,
      default: () => ({})
    },

  },
  data () {
    return {
      zoom: 9,
      maxZoom: 22,
      center: [43.39228, -5.66335],
      bounds: undefined,
      padding: [10, 10],
      mapOptions: {
        zoomControl: false,
        attributionControl: false
      },
      tileLayerClass: (url, options) => L.mapboxGL(options),
      layerOptions: {
        style:
          this.$MAP_SERVER_URL
      },
      optionsLayer: [
        {
          name: 'Street Map',
          attribution: 'Street Map',
          opacity: 0
        }
      ],
      map: null,
      coordinatesLat: '',
      coordinatesLng: '',
      countSearch: 0,
      valueStation: null,
      position: [],
      latlng: [],
      stationMarker: {},
      loadingSelf: {
        isVisible: false,
        message: 'Loading Station'
      }
    }
  },
  computed: {
    ...mapState(['markers']),
    gridCenter () {

      // Initializing proj4 parameters
      const corner1Location = proj4(
        this.$sessionStorage.projectCoordSystem,
        this.$WGS84,
        [this.gridBounds.Xmin, this.gridBounds.Ymin]
      ) || []
      const corner2Location = proj4(
        this.$sessionStorage.projectCoordSystem,
        this.$WGS84,
        [this.gridBounds.Xmax, this.gridBounds.Ymax]
      ) || []
      const corner1 = L.latLng(corner1Location[1], corner1Location[0])
      const corner2 = L.latLng(corner2Location[1], corner2Location[0])

      return L.latLngBounds(corner1, corner2)
    },
  },
  mounted () {
    // this.center = [43.39228, -5.66335]
    this.bounds = this.gridCenter
  },
  methods: {

    zoomUpdate (zoom) {
      this.zoom = zoom
      if (this.countSearch === 0) {
        this.countSearch = this.countSearch + 1
        setTimeout(() => {
          this.center = [43.38228, -5.76335]
        }, 50)

        this.selectElement(this.valueStation)
      }
    },
    onMapReady () {
      this.map = this.$refs.myMap.mapObject
      this.map.on('mousemove', (e) => {
        const cLat = e.latlng.lat.toFixed(6)
        const cLng = e.latlng.lng.toFixed(6)
        this.coordinatesLat = cLat
        this.coordinatesLng = cLng
      })
    },
    selectElement (valueStation) {
      this.valueStation = valueStation
      const stationObjeto = this.stations.find(station => station.name === valueStation)

      this.showLoading(true)
      setTimeout(() => {
        this.center = this.getLatLongProj(stationObjeto.coordinates[0], stationObjeto.coordinates[1])
        this.zoomUpdate(19)
      }, 1000)
      setTimeout(() => { this.showLoading(false) }, 1000)
    },
    getLatLongProj (lat, long) {
      // HERE YOU HAVE TO PASS X,Y IN THE 3 PARAMETER, AND THEN CHANGE THE ORDER
      const loc = proj4(this.$sessionStorage.projectCoordSystem, this.$WGS84, [long, lat])
      const location = [loc[1], loc[0]]
      // console.log(location)
      return location
    },
    openContextMenu (event) {
      this.$store.dispatch('setElement', { path: 'TIA.TIAMap.contextMenu', value: true })
      const position = [event.containerPoint.x + 10, event.containerPoint.y + 75]
      this.position = position
      this.stationMarker = this.findByCoordinates([event.latlng.lat, event.latlng.lng], this.stations)
    },
    clickMap () {
      this.$store.dispatch('setElement', { path: 'TIA.TIAMap.contextMenu', value: false })
    },
    findByCoordinates (coordenadas, array) {
      for (const objeto of array) {
        const latlngReal = this.getLatLongProj(objeto.latitude, objeto.longitude)
        if (latlngReal[0] === coordenadas[0] && latlngReal[1] === coordenadas[1]) {
          return { is_flex: objeto.is_flex, uuid: objeto.uuid }
        }
      }
      return null
    },
    showLoading (show) {
      this.loadingSelf.isVisible = show
    }

  }
}
</script>

<style>
#map {
  height: 88vh;
}
.leaflet-top {
  z-index: 500;
}
.v-overlay__content {
  z-index: 502;
}
.leaflet-bottom {
  bottom: 40px;
}
.nav-bar-map-tia {
  height: 80px!important;
}
</style>
