<template>
  <v-dialog
    v-model="showForm"
    style="z-index:503"
    max-width="900"
    hide-overlay
    overlay-opacity="0.5"
  >
    <v-card
      v-show="!subdialog"
      elevation="10"
    >
      <v-form
        ref="EVForm"
        v-model="valid"
        lazy-validation
      >
        <v-card-title class="text-h5">
          {{ $t('new_connections.current_changes') }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-expansion-panels
              v-model="panel"
              inset
              multiple
            >
              <v-row>
                <v-col
                  v-for="(change,i) in changes"
                  :key="i"
                  cols="4"
                >
                  <v-card class="py-2">
                    <v-img
                      contain
                      height="8vh"
                      :src="`@/assets/icons/SVG/${ICONS[change.device]}`"
                    />
                    <v-divider class="mx-4 mt-4" />
                    <v-card-title v-text="setCardTitle(change)" />
                    <v-card-text>
                      <v-expansion-panel>
                        <v-expansion-panel-header hide-actions>
                          <v-btn>{{ panel.includes(i) ? $t('new_connections.hide_details') : $t('new_connections.see_details') }}</v-btn>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="limit-container">
                          <v-list>
                            <v-list-item
                              v-for="(key,j) in setKeys(change)"
                              :key="j"
                              style="text-align:left; padding-left:0; min-height: 30px"
                            >
                              <v-list-item-content>
                                <v-list-item-title class="py-0">
                                  {{ key }}: {{ change[key] }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                          <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                data-cy="dt-edit-element-icon"
                                color="#f4c020"
                                outlined
                                fab
                                v-on="on"
                                @click="() => openFormEdit(change, indexes[i], i)"
                              >
                                <v-icon>{{ pencilIcon }}</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t('new_connections.edit_element') }}</span>
                          </v-tooltip>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panels>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#f4c020"
            data-cy="dt-edit-cancel"
            style="margin-right: 15px"
            text
            @click="closeForm()"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <FormComponent
      :is="FormComponent"
      v-if="subdialog"
      :payload="payloadToEdit"
      :show-form="subdialog"
      @closeForm="subdialog = false"
    />
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import digitalTwinForm from '@/mixins/digitalTwinForm'
import dtChangesMixin from '@/mixins/dtChangesMixin'
import { ICONS } from '@/store/constants'
import { mdiLeadPencil } from '@mdi/js'

export default {
  name: 'DTEditChangesForm',

  mixins: [digitalTwinForm, dtChangesMixin],

  data: () => ({
    pencilIcon: mdiLeadPencil,
    panel: [],
    subdialog: false,
    FormComponent: undefined,
    payloadToEdit: undefined,
    currentChanges: undefined
  }),

  computed: {
    ...mapState(['DTChanges']),
    changes () {
      return Object
        .entries(this.currentChanges)
        .map(([type, data]) => {
          const sortedData = data.CGP ? [...data.CGP] : [...data.Node] // Node for ESS
          const change = {}
          return sortedData.map((_, index) => {
            Object.keys(data, index).forEach((key) => {
              change[key] = data[key][index]
            })
            return ({ ...change, device: type })
          })
        })
        .filter((c) => c.length)
        .flat()
    },

    indexes () {
      const self = []
      this.changes.forEach(({ device }, index, arr) => {
        const { device: prevDevice } = arr[index - 1] || {}
        let counter = index ? self.at(-1) : index

        counter = prevDevice === device ? counter + 1 : 0
        self.push(counter)
      })

      return self
    },

    ICONS () {
      return ICONS
    }
  },

  watch: {
    changes (val) {
      !val.length && this.$emit('closeForm')
    }
  },

  methods: {
    setKeys (obj) {
      return Object.keys(obj).filter((key) => !['ndevs', 'device', 'CGP'].includes(key))
    },

    setCardTitle ({ device, ndevs }) {
      return `${device} ${ndevs ? `( ${this.$t('new_connections.quantity')}: ${ndevs})` : 'Changes'}`
    },

    openFormEdit (change, deviceIndex, generalIndex) {
      this.$store.dispatch('setElement', {
        path: 'currentMarker',
        value: this.markers
          .filter((m) => m.id.includes(this.connectionPoint.ID))
          .sort((a, b) => (parseInt(a.id, 10) > parseInt(b.id, 10) ? -1 : 1))
          .find((_, index) => index === generalIndex)
      })

      this.subdialog = true
      this.FormComponent = this.payload.forms[change.device]
      this.payloadToEdit = {
        edit: true,
        changes: change,
        customIndex: deviceIndex,
        ...(change.device === 'Phase' && { actionType: 'Change Phase' })
      }
    }
  }
}
</script>

<style scoped>
.limit-container {
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.v-expansion-panel-content {
  max-width: 300px;
}
</style>
