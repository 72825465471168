import { getAreaGridByZone, getAreaGridByElement } from '@/services/areaSelector'
import vuexMixin from '@/mixins/vuexMixin'
import formatFloatKey from '@/utils/formatFloatKey'

export default {
  mixins: [vuexMixin],

  methods: {
    storeGridBounds (areaGrid) {
      const KEYS = ['Xmax', 'Xmin', 'Ymax', 'Ymin']

      KEYS.forEach(key => {
        window.sessionStorage[key] = formatFloatKey(areaGrid, `@@${key}`)
      })
    },

    async getAreaGrid ({ isZone, withEvent, redirect, value } = {}) {
      const getByZone = async () => {
        try {
          const areaGrid = await getAreaGridByZone(value)

          if (areaGrid) {
            this.setVuexElement({
              path: 'areaGrid',
              value: areaGrid
            })

            if (withEvent) this.$emit('getArea')
          }

          return areaGrid
        } catch (err) {
          return err
        }
      }

      const getByElement = async () => {
        try {
          return await getAreaGridByElement(value)
        } catch (err) {
          return err
        }
      }

      const areaGrid = isZone
        ? await getByZone()
        : await getByElement()

      if (areaGrid.code) return areaGrid

      if (areaGrid) this.storeGridBounds(areaGrid)
      if (redirect) this.$router.push('/home')
    }
  }
}
